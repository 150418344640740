import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { TherapistCredentialNote, CredentialNoteStatus, TherapistSmallObject, ChoiceOption } from '../../../utils/Definitions';
import { GlobalContext } from '../../../utils/GlobalContext';
import { apiConfig } from "../../../authConfig";
import { CredentialsContext } from '../../../pages/credentials/CredentialsContext';
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';
import Select, { SingleValue } from "react-select";

interface Props {
    Active: boolean,
    isPermanent: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>,
    clinicians: TherapistSmallObject[],
    saveAllocation: any,
}


export const AddClinicianModal: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [effectiveDate, setEffectiveDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>();
    const [selectedClinician, setSelectedClinician] = useState<ChoiceOption | null>(null);
    const [reason, setReason] = useState<string | null>("");

    const [endDateValidation, setEndDateValidation] = useState<boolean>(true);

    function OnClinicianChange(input: SingleValue<ChoiceOption>) {

        if (props.clinicians) {

            var foundClinician = props.clinicians?.find(z => String(z.therapistId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundClinician) {
                temp = ({ value: String(foundClinician.therapistId), label: foundClinician.employeeName })
                setSelectedClinician(temp);
            }
        }
    }

    function EndDateValidationCheck() {
        if (endDate == null) {
            setEndDateValidation(true);
            return;
        }
        else {
            if (effectiveDate && (endDate <= effectiveDate)) {
                setEndDateValidation(false);
            }
            else {
                setEndDateValidation(true);
            }
        }
    }

    useEffect(() => {
        EndDateValidationCheck();
    }, [endDate]);

    useEffect(() => {
        setEndDate(null);
        setEffectiveDate(new Date());
        setSelectedClinician(null);
        setReason("");

        const textarea = document.getElementById('allowcationReason') as HTMLTextAreaElement;
        if (textarea) {
            textarea.value = "";
        }

    }, [props.Active]);

    function CloseModal() {
        props.setActive(false);
    }

    return (
        <div className={"z-10 relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div>
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <h3 className="text-lg font-bold leading-6 text-center text-lacblue" id="modal-title">{props.isPermanent ? "Add Permanent Clinician" : "Add Temporary Clinician"}</h3>
                                    <div className="mt-2">
                                        <div className="w-full">
                                            <label className="block text-center font-bold mb-2" htmlFor="pods">
                                                Clinician:
                                            </label>
                                            <div className="w-full">
                                                <Select className="w-full" options={props.clinicians && props.clinicians.length > 0 ? props.clinicians.map((clinician) => ({ value: String(clinician.therapistId), label: clinician.employeeName })) : undefined} onChange={OnClinicianChange} value={selectedClinician} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-2 flex space-x-4">
                                        <div className={!props.isPermanent ? "w-1/2" : "w-full"}>
                                            <label className="block text-center font-bold mb-2" htmlFor="effectiveDatePicker">
                                                Effective Date:
                                            </label>
                                            <div className="w-full">
                                                <DatePicker
                                                    value={effectiveDate}
                                                    disabled={false}
                                                    disableFuture={false}
                                                    onChange={(newValue) => {
                                                        setEffectiveDate(newValue);
                                                    }}
                                                    className="w-full"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            id="effectiveDatePicker"
                                                            size="small"
                                                            className="w-full"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={"w-1/2 " + (!props.isPermanent ? "normal" : "hidden")}>
                                            <label className="block text-center font-bold mb-2" htmlFor="endDatePicker">
                                                End Date:
                                            </label>
                                            <div className="w-full">
                                                <DatePicker
                                                    value={endDate}
                                                    disabled={false}
                                                    disableFuture={false}
                                                    onChange={(newValue) => {
                                                        setEndDate(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            id="endDatePicker"
                                                            size="small"
                                                            className="w-full"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3 w-full">
                                        <label className="block font-bold mb-2" htmlFor="therapistNote">
                                            Reason For Addition:
                                        </label>

                                        <textarea id="allowcationReason" className="block p-2.5 w-full h-48 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            onChange={(newValue) => setReason(newValue.target.value)}
                                            maxLength={256}
                                            placeholder="Write your reason here..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex bg-gray-50 px-4 py-3 sm:px-6 mx-auto">
                            <div className="flex justify-center mx-auto">
                                <div className="pr-5">
                                    <button onClick={CloseModal} className="bg-lacyellow w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600"><span className="drop-shadow">Discard</span></button>
                                </div>

                                <div>
                                    <button onClick={() => props.saveAllocation(props.isPermanent, effectiveDate, endDate, selectedClinician?.value, reason)} disabled={(!selectedClinician || !endDateValidation)} className={`bg-lacgreen w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out ${(selectedClinician && endDateValidation) ? 'hover:bg-lacgreen-600' : 'opacity-50 cursor-not-allowed'}`}><span className="drop-shadow">Save</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    );
}

