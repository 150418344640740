// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { OutcomesClientCard } from "../../components/outcomes/OutcomesClientCard";
import { useNavigate } from "react-router-dom";
import { Center, Client, Pod } from "../../utils/Definitions";
import { OutcomesContext } from "./OutcomesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalNotification } from "../../components/ModalNotification";
import { zonedTimeToUtc } from 'date-fns-tz'


//only renders if authenticated
const OutcomesHomeContent = () => {


    const navigate = useNavigate();

    const outcomesContext = useContext(OutcomesContext);
    const globalContext = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(true);


    const [centers, setCenters] = useState<Center[]>();
    function HandleCenterChange(event: { target: { value: string; }; }) {
        outcomesContext.setSelectedCenter(JSON.parse(event.target.value));
    }

    const [pods, setPods] = useState<Pod[]>();

    const [clientFilter, setClientFilter] = useState<string>('');
    const [clients, setClients] = useState<Client[]>();
    function HandleClientFilterChange(event: { target: { value: string; }; }) {
        setClientFilter(event.target.value);
    }

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    function UpdateCenters() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawcenters:", result);
                        setCenters(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdatePods() {

        if (outcomesContext && outcomesContext.selectedCenter && outcomesContext.selectedCenter.centerId) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                //var bearer = "Bearer " + globalContext.CoreAccount.token;
                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Pods?centerid=" + outcomesContext.selectedCenter?.centerId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setPods(result);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Pods" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
            
        }
        
    }

    function UpdateClients() {
        if (outcomesContext.selectedCenter && outcomesContext.selectedPod) {

            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                //var bearer = "Bearer " + globalContext.CoreAccount.token;
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients?centerid=" + outcomesContext.selectedCenter?.centerId + "&podid=" + outcomesContext.selectedPod?.podId + "&getall=" + outcomesContext.getAllClients;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw clients:", result);
                            setClients(result);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
        
    }

    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateCenters();
        }
    }, [globalContext.CoreAccount.token]);

    useEffect(() => {
        //select first center if there is no selected center
        if (centers && centers.length > 0 && !outcomesContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    outcomesContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    outcomesContext.setSelectedCenter(centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                outcomesContext.setSelectedCenter(centers[0]);
            }
                                
        }
        
    }, [centers]);

    //update pods
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdatePods();
        }
    }, [outcomesContext.selectedCenter]);

    useEffect(() => {
        //select first pod if there is no selected pod, or if selected pod is NOT in pod list
        if (pods && pods.length > 0 && (!outcomesContext.selectedPod || (outcomesContext.selectedPod && pods.filter(x => x.podId == outcomesContext.selectedPod?.podId).length == 0))) {

            //attempt to use current user's pod as default, if not, select first pod
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.podID && globalContext.CoreAccount.currentUser.podID != null) {
                var pod = pods.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.podId == globalContext.CoreAccount.currentUser.podID)
                if (pod) {
                    outcomesContext.setSelectedPod(pod);
                }
                else {
                    outcomesContext.setSelectedPod(pods[0]);
                }
            }
            else {
                outcomesContext.setSelectedPod(pods[0]);
            }

        }
    }, [pods]);

    //clients
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateClients();
        }
    }, [outcomesContext.selectedPod]);

    //update clients on toggle change
    useEffect(() => {
        if (clients) {
            UpdateClients();
        }
        
    }, [outcomesContext.getAllClients]);

    function NavigateBack() {
        navigate("/ecl");
    }

    if (!loading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div className="grid grid-flow-row">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:grid-cols-4 xl:w-fit xl:m-auto xl:mt-4">

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="search">
                                    Search:
                                </label>
                                <input autoComplete="off" onChange={HandleClientFilterChange} type="text" id="search" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" placeholder="Search..." />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="centers">
                                    Center:
                                </label>

                                <Select options={centers} onChange={outcomesContext.setSelectedCenter} value={outcomesContext.selectedCenter} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="pods">
                                    Pod:
                                </label>
                                <Select options={pods} onChange={outcomesContext.setSelectedPod} value={outcomesContext.selectedPod} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto xl:mt-auto xl:mb-1">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={event => outcomesContext.setGetAllClients(event.target.checked)} checked={outcomesContext.getAllClients} className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">Show All Learners</span>
                                </label>
                            </div>

                        </div>



                        <div className="grid grid-cols-1 gap-6 place-items-center xl:grid-cols-2 w-fit m-auto mt-8">
                            {clients ? clients.filter(client => client.fullName.toLowerCase().includes(String(clientFilter.toLowerCase()))).map(client => <OutcomesClientCard SetProfilePictureModalActive={setProfilePictureModalActive} key={client.clientId} Client={client} SetSelectedClientForModal={setSelectedClientForModal} SetCredentialsModalActive={setCredentialsModalActive} />) : <></>}
                        </div>
                    </div>
               
                </div>

                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose}  />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />

            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
    
    
};


export function OutcomesHome() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Outcomes.Read', 'Outcomes.Write']}>
                    <OutcomesHomeContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};