import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../../pages/edpn/EDPNContext";
import { VwCenterAllocation } from "../../../utils/Definitions";
import ProtectedComponent from "../../../utils/ProtectedComponent";
import { apiConfig } from "../../../authConfig";
import { GlobalContext } from "../../../utils/GlobalContext";
import { format, isAfter, set, sub } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";


interface Props {
    centerAllocation: VwCenterAllocation;
    isPermanent: boolean;
    removeClinican: any;
}


export const CenterClinicians: React.FC<Props> = (props) => {

    const [signatureSrc, setSignatureSrc] = useState<string>("");

    useEffect(() => {
        //set signature image
        if (props.centerAllocation.signatureImage) {
            setSignatureSrc(`data:image/png;base64,${props.centerAllocation.signatureImage}`);
        }
        else {
            setSignatureSrc("");
        }
    }, [props.centerAllocation]);

    if (props.isPermanent) {
        return (
            <tr key={props.centerAllocation.therapistId} className="text-center border border-gray-500 max-h-[50px]">
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.firstName + " " + props.centerAllocation.lastName}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.jobTitle}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.credentials}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.bcbacertDate ? new Date(props.centerAllocation.bcbacertDate).toLocaleDateString() : "N/A"}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span><img className={"m-auto max-h-[20px] " + (signatureSrc == "" ? 'hidden' : '')} src={signatureSrc} alt="Image" /></span></td>
                <td onClick={() => props.removeClinican(props.centerAllocation.therapistId)} className="border border-gray-500"><span><FontAwesomeIcon className=" text-lacblue w-5 h-5 px-1 m-auto hover:cursor-pointer" icon={solid('trash-can')}></FontAwesomeIcon></span></td>
            </tr>
        )
    }
    else {
        return (
            <tr key={props.centerAllocation.therapistId} className="text-center border border-gray-500 max-h-[50px]">
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.firstName + " " + props.centerAllocation.lastName}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.jobTitle}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.credentials}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.bcbacertDate ? new Date(props.centerAllocation.bcbacertDate).toLocaleDateString() : "N/A"}</span></td>
                <td className="border border-gray-500 max-h-[50px]"><span><img className={"m-auto max-h-[20px] " + (signatureSrc == "" ? 'hidden' : '')} src={signatureSrc} alt="Image" /></span></td>
                <td className="border border-gray-500 max-h-[50px]"><span>{props.centerAllocation.dateEnd ? new Date(props.centerAllocation.dateEnd).toLocaleDateString() : "N/A"}</span></td>
                <td onClick={() => props.removeClinican(props.centerAllocation.therapistId)} className="border border-gray-500"><span><FontAwesomeIcon className=" text-lacblue w-5 h-5 px-1 m-auto hover:cursor-pointer" icon={solid('trash-can')}></FontAwesomeIcon></span></td>
            </tr>
        )
    }
    
};