import { ALL } from 'dns';
import { ClientSessionOptions } from 'http2';
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { apiConfig } from '../../authConfig';
import { AbsentReason, Center, ChoiceOption, Client, CPTCode, EDPNAnswerObject, Pod, TherapistOption } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';
import { ECLContext } from '../ecl/ECLContext';


//props for global context
type EDPNContextProviderProps = {
    children: React.ReactNode
}

//definition of export type
type EDPNContextType = {
    setSelectedCenter: React.Dispatch<React.SetStateAction<Center | null>>;
    selectedCenter: Center | null;
    setSelectedPod: React.Dispatch<React.SetStateAction<Pod | null>>;
    selectedPod: Pod | null;
    setSelectedClient: React.Dispatch<React.SetStateAction<Client | null>>;
    selectedClient: Client | null;
    setShowInactiveClients: React.Dispatch<React.SetStateAction<boolean>>;
    showInactiveClients: boolean;
    clients: Client[];
    clientFilter: string;
    setClientFilter: React.Dispatch<React.SetStateAction<string>>;
    filteredPods: Pod[] | null;
    setFilteredPods: React.Dispatch<React.SetStateAction<Pod[] | null>>;
    selectedEditAnswer: EDPNAnswerObject | null;
    setSelectedEditAnswer: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    isViewOnly: boolean;
    setIsViewOnly: React.Dispatch<React.SetStateAction<boolean>>;
    allChoiceOptions: ChoiceOption[] | null;
    selectedRBTs: TherapistOption[];
    setSelectedRBTs: React.Dispatch<React.SetStateAction<TherapistOption[]>>;
    CPTCodes: CPTCode[] | null;
    selectedCPTCode: CPTCode | null;
    setSelectedCPTCode: React.Dispatch<React.SetStateAction<CPTCode | null>>;
    servicePlaces: ChoiceOption[] | null;
    selectedServicePlace: ChoiceOption | null;
    setSelectedServicePlace: React.Dispatch<React.SetStateAction<ChoiceOption | null>>;
    absentReasons: AbsentReason[] | null;
    setAbsentReasons: React.Dispatch<React.SetStateAction<AbsentReason[] | null>>;
    exampleModalActive: boolean;
    setExampleModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    exampleMessage: string;
    setExampleMessage: React.Dispatch<React.SetStateAction<string>>;
    groupEditNavigating: boolean;
    setGroupEditNavigating: React.Dispatch<React.SetStateAction<boolean>>;
    clientsFiltered: Client[];
    autosaveFlag: boolean;
    setAutosaveFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

//initilize context
export const EDPNContext = createContext<EDPNContextType>({
    setSelectedCenter: function noop() { },
    selectedCenter: null,
    setSelectedPod: function noop() { },
    selectedPod: null,
    setSelectedClient: function noop() { },
    selectedClient: null,
    setShowInactiveClients: function noop() { },
    showInactiveClients: false,
    clients: [],
    clientFilter: "",
    setClientFilter: function noop() { },
    filteredPods: null,
    setFilteredPods: function noop() { },
    selectedEditAnswer: null,
    setSelectedEditAnswer: function noop() { },
    isViewOnly: false,
    setIsViewOnly: function noop() { },
    allChoiceOptions: null,
    selectedRBTs: [],
    setSelectedRBTs: function noop() { },
    CPTCodes: [],
    selectedCPTCode: null,
    setSelectedCPTCode: function noop() { },
    servicePlaces: [],
    selectedServicePlace: null,
    setSelectedServicePlace: function noop() { },
    absentReasons: [],
    setAbsentReasons: function noop() { },
    exampleModalActive: false,
    setExampleModalActive: function noop() { },
    exampleMessage: "",
    setExampleMessage: function noop() { },
    groupEditNavigating: false,
    setGroupEditNavigating: function noop() { },
    clientsFiltered: [],

    autosaveFlag: false,
    setAutosaveFlag: function noop() { },
});


export const EDPNContextProvider = ({ children, }: EDPNContextProviderProps) => {

    const globalContext = useContext(GlobalContext);

    //account state
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  
    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);
    const [selectedPod, setSelectedPod] = useState<Pod | null>(null);
    const [showInactiveClients, setShowInactiveClients] = useState<boolean>(false);

    const [filteredPods, setFilteredPods] = useState<Pod[] | null>(null);
    const [clients, setClients] = useState<Client[]>([]);
    const [clientsFiltered, setClientsFiltered] = useState<Client[]>([]);
    const [clientFilter, setClientFilter] = useState<string>("");

    const [selectedRBTs, setSelectedRBTs] = useState<TherapistOption[]>([]);

    const [selectedEditAnswer, setSelectedEditAnswer] = useState<EDPNAnswerObject | null>(null);

    const [isViewOnly, setIsViewOnly] = useState<boolean>(false);


    const [allChoiceOptions, setAllChoiceOptions] = useState<ChoiceOption[] | null>(null);

    const [CPTCodes, setCPTCodes] = useState<CPTCode[] | null>(null);
    const [selectedCPTCode, setSelectedCPTCode] = useState<CPTCode | null>(null);

    const [servicePlaces, setServicePlaces] = useState<ChoiceOption[] | null>(null);
    const [selectedServicePlace, setSelectedServicePlace] = useState<ChoiceOption | null>(null);

    const [exampleModalActive, setExampleModalActive] = useState<boolean>(false);
    const [exampleMessage, setExampleMessage] = useState<string>("");

    const [absentReasons, setAbsentReasons] = useState<AbsentReason[] | null>(null);

    const [groupEditNavigating, setGroupEditNavigating] = useState<boolean>(false);

    const [autosaveFlag, setAutosaveFlag] = useState<boolean>(false);

    const location = useLocation();



    useEffect(() => {
        //select first center if there is no selected center
        if (globalContext.centers && globalContext.centers.length > 0 && !selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = globalContext.centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    setSelectedCenter(globalContext.centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                setSelectedCenter(globalContext.centers[0]);
            }
        }

    }, [globalContext.centers]);

    useEffect(() => {
        if (selectedCenter && selectedCenter.centerId && globalContext.pods) {
            setFilteredPods(globalContext.pods.filter(x => x.centerId == selectedCenter.centerId));
        }
    }, [selectedCenter, globalContext.pods]);

    useEffect(() => {
        console.log("selected client:", selectedClient);
    }, [selectedClient]);

    useEffect(() => {
        if (selectedCenter) {
            UpdateAllClients();
        }
    }, [selectedCenter, showInactiveClients]);

    useEffect(() => {

        if (clientFilter && clientFilter.length > 0) {
            //search
            setClientsFiltered(clients?.filter(x => x.fullName.toLowerCase().includes(String(clientFilter.toLowerCase()))));
        }
        else {
            //filter by pod
            setClientsFiltered(clients?.filter(x => x.podId == selectedPod?.podId));
        }

    }, [clientFilter, selectedPod, clients]);


    useEffect(() => {
        //select first pod if there is no selected pod, or if selected pod is NOT in pod list
        if (filteredPods && filteredPods.length > 0 && (!selectedPod || (selectedPod && filteredPods.filter(x => x.podId == selectedPod?.podId).length == 0))) {

            //attempt to use current user's pod as default, if not, select first pod
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.podID && globalContext.CoreAccount.currentUser.podID != null) {
                var pod = filteredPods.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.podId == globalContext.CoreAccount.currentUser.podID)
                if (pod) {
                    setSelectedPod(pod);
                }
                else {
                    setSelectedPod(filteredPods[0]);
                }
            }
            else {
                setSelectedPod(filteredPods[0]);
            }
        }
    }, [filteredPods]);

    useEffect(() => {
        UpdateCPTCodes();
        UpdateServicePlaces();
        UpdateTardyReasons();
    }, [globalContext.CoreAccount.token]);

    useEffect(() => {
        if (selectedEditAnswer && selectedEditAnswer.placeOfServiceId && servicePlaces) {

            var tempPlace = servicePlaces.find(x => x.value == String(selectedEditAnswer.placeOfServiceId));

            if (tempPlace) {
                setSelectedServicePlace(tempPlace);
            }
        }
    }, [selectedEditAnswer, servicePlaces]);

    function UpdateAllClients() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/Clients/GetClientsByCenter?centerid=" + selectedCenter?.centerId + "&getall=" + showInactiveClients;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setClients(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetClientsByCenter" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateCPTCodes() {

        globalContext.GetToken().then((token: any) => {

            if (token) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Admin/GetCPTCodes?app=DPN";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("edpnContext UpdateCPTCODES raw:", result);

                            setCPTCodes(result);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetCPTCodes" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    function UpdateServicePlaces() {

        globalContext.GetToken().then((token: any) => {

            if (token) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Admin/GetPlacesOfService?app=DPN";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw places:", result);
                            setServicePlaces(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetPlacesOfService" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    function UpdateTardyReasons() {

        globalContext.GetToken().then((token: any) => {

            if (token) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetAbsentReasons";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAbsentReasons(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetAbsentReasons" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    //initilize object that is accessible in the edpn app
    const EDPNContextExport: EDPNContextType = {
        selectedCenter: selectedCenter,
        setSelectedCenter: setSelectedCenter,
        selectedPod: selectedPod,
        setSelectedPod: setSelectedPod,
        selectedClient: selectedClient,
        setSelectedClient: setSelectedClient,
        setShowInactiveClients: setShowInactiveClients,
        showInactiveClients: showInactiveClients,
        clients: clients,
        clientFilter: clientFilter,
        setClientFilter: setClientFilter,
        filteredPods: filteredPods,
        setFilteredPods: setFilteredPods,
        selectedEditAnswer: selectedEditAnswer,
        setSelectedEditAnswer: setSelectedEditAnswer,
        isViewOnly: isViewOnly,
        setIsViewOnly: setIsViewOnly,
        allChoiceOptions: allChoiceOptions,
        selectedRBTs: selectedRBTs,
        setSelectedRBTs: setSelectedRBTs,
        CPTCodes: CPTCodes,
        selectedCPTCode: selectedCPTCode,
        setSelectedCPTCode: setSelectedCPTCode,
        servicePlaces: servicePlaces,
        selectedServicePlace: selectedServicePlace,
        setSelectedServicePlace: setSelectedServicePlace,
        absentReasons: absentReasons,
        setAbsentReasons: setAbsentReasons,
        exampleMessage: exampleMessage,
        setExampleMessage: setExampleMessage,
        exampleModalActive: exampleModalActive,
        setExampleModalActive: setExampleModalActive,
        groupEditNavigating: groupEditNavigating,
        setGroupEditNavigating: setGroupEditNavigating,
        clientsFiltered: clientsFiltered,
        autosaveFlag: autosaveFlag,
        setAutosaveFlag: setAutosaveFlag,
    };

    return <EDPNContext.Provider value={EDPNContextExport}>{children}</EDPNContext.Provider>;
}