import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { EDPNAnswerObject, Therapist } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { apiConfig } from "../../authConfig";
import { GlobalContext } from "../../utils/GlobalContext";
import { format, isAfter, set, sub } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";


interface Props {
    Log: EDPNAnswerObject,
    Therapists: Therapist[] | null,
    ApproveLog: any,
    UnapproveLog: any,
    DeleteLog: any,
    HoldLog: any,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
}

export const EDPNApprovalCard: React.FC<Props> = (props) => {

    // Accessing the global context using the useContext hook
    const globalContext = useContext(GlobalContext);

    // Accessing the EDPN context using the useContext hook
    const edpnContext = useContext(EDPNContext);

    // Accessing the navigate function using the useNavigate hook
    const navigate = useNavigate();

    // Initializing the "isExpanded" state variable with a default value of "false"
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const [viewEditButtonLoading, setViewEditButtonLoading] = useState<boolean>(false);



    // This is an asynchronous function that navigates to the edit log page
    async function NavigateToEditLog() {

        if (props.Log && props.Log.clientId && props.Log.logId) {

            setViewEditButtonLoading(true);

            const client = await GetClientByID(props.Log.clientId);
            const log = await GetLogByID(props.Log.logId);

            setViewEditButtonLoading(false);

            edpnContext.setSelectedClient(client);
            edpnContext.setSelectedEditAnswer(log);
            edpnContext.setIsViewOnly(false);

            if (props.Log.absent == false) {
                navigate("/edpn/approvals/editlog");
            }
            else {
                navigate("/edpn/approvals/editabsent");
            }
        }
        
    }


    async function NavigateToViewLog() {

        if (props.Log && props.Log.clientId && props.Log.logId) {

            setViewEditButtonLoading(true);

            const client = await GetClientByID(props.Log.clientId);
            const log = await GetLogByID(props.Log.logId);

            setViewEditButtonLoading(false);

            edpnContext.setSelectedClient(client);
            edpnContext.setSelectedEditAnswer(log);
            edpnContext.setIsViewOnly(true);

            if (props.Log.absent == false) {
                navigate("/edpn/approvals/viewlog");
            }
            else {
                navigate("/edpn/approvals/viewabsent");
            }
        }
    }

    // This is an asynchronous function that retrieves a client object by ID
    async function GetClientByID(id: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Clients/GetClientByID?clientid=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Clients/GetClientByID" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    async function GetLogByID(id: string) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/EDPN/GetLog?id=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/EDPN/GetLog" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    // This function retrieves the name of a therapist with the given id
    function GetTherapistName(id: number) {
        if (props.Therapists) {

            const therapist = props.Therapists.find(x => x.therapistId === id);

            // If a therapist with the matching id is found, return their name
            if (therapist) {
                return therapist.employeeName;
            }
            // If a therapist with the matching id is not found, return the id as a string
            else {
                return String(id);
            }
        }
        // If the props.Therapists array is not defined, return the id as a string
        else {
            return String(id);
        }
    }

    // This function toggles the expansion state of a card
    function ToggleExpand() {
        setIsExpanded(!isExpanded);
    }

    // This function warns the user about missing credentials
    function WarnUserMissingCredentials() {
        props.SetCredentialsModalActive(true);
    }

    // This component displays an expand/collapse button for a card
    const ExpandButton = () => {
        // If the card is expanded, display an "up" arrow button
        if (isExpanded) {
            return (
                <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-up')} />
            );
        }
        // If the card is not expanded, display a "down" arrow button
        else {
            return (
                <FontAwesomeIcon onClick={ToggleExpand} className="fill-current w-8 h-8 cursor-pointer my-auto" icon={solid('angle-down')} />
            );
        }
    }

    // This component displays the narrative section of a log entry
    const Narrative = () => {
        // If the card is expanded, display the narrative section
        if (isExpanded) {
            return (
                <div className="mt-4 w-full xl:w-[47rem]">

                    <span className="font-bold">Session Summary:</span>

                    {/* Use dangerouslySetInnerHTML to render the narrative content as HTML */}
                    <div dangerouslySetInnerHTML={{ __html: props.Log.narrative != null ? props.Log.narrative : "N/A" }} />

                </div>
            );
        }
        // If the card is not expanded, display nothing
        else {
            return <></>
        }
    }

    const ViewLogButton = () => {
        if (viewEditButtonLoading) {
            return (
                <button disabled={true} className="bg-lacyellow m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacyellow-600">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Loading</span>
                </button>
            );
        }
        else {
            return (<button onClick={NavigateToViewLog} className="bg-lacyellow m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600"><span className="drop-shadow">View</span></button>)
        }
    }

    // This component displays the status of a log entry based on the dpnstatusId property of the props
    const Status = () => {
        // Use a switch statement to determine the correct status to display based on dpnstatusId
        switch (props.Log.dpnstatusId) {
            case 1:
                // If dpnstatusId is 1, display "Approved" in green text
                return (
                    <span className="text-lacgreen font-bold text-lg">Approved</span>
                )
            case 2:
                // If dpnstatusId is 2, display "Pending Correction" in yellow text
                return (
                    <span className="text-yellow font-bold text-lg">Pending Correction</span>
                )
            case 3:
                // If dpnstatusId is 3, display "Imported" in blue text
                return (
                    <span className="text-lacblue font-bold text-lg">Imported</span>
                )
            case 4:
                // If dpnstatusId is 4, display "Created" in pink text
                return (
                    <span className="text-rose font-bold text-lg">Created</span>
                )
            case 5:
                // If dpnstatusId is 5, display "On Hold" in purple text
                return (
                    <span className="text-purple font-bold text-lg">On Hold</span>
                )
            case 6:
                // If dpnstatusId is 6, display "Incomplete" in teal text
                return (
                    <span className="text-teal font-bold text-lg">Incomplete</span>
                )
            case 7:
                // If dpnstatusId is 7, display "Pending Approval" in teal text
                return (
                    <span className="text-orange font-bold text-lg">Pending Approval</span>
                )
            default:
                // If dpnstatusId is not in the above cases, return an empty fragment
                return <></>;
        }
    }

    const DeleteLogButton = () => {

        // Show delete button ONLY if current user is author OR if current user is BCBA
        if ((globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID && props.Log.therapistId == globalContext.CoreAccount.currentUser.therapistID) || (globalContext.CoreAccount.currentUser?.bcba)) {
            switch (props.Log.dpnstatusId) {
                case 1:
                    // Approved, do not show delete button
                    return (
                        <></>
                    )
                case 2:
                    // Pending correction, show delete button if therapist has credentials and signature image
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        // Show warning message if therapist is missing credentials or signature image
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }

                case 3:
                    // Imported, do not show delete button
                    return (
                        <></>
                    )
                case 4:
                    // Created, show delete button if therapist has credentials and signature image
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        // Show warning message if therapist is missing credentials or signature image
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                case 6:
                    // incomplete, show delete button if therapist has credentials and signature image
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        // Show warning message if therapist is missing credentials or signature image
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                case 7:
                    // Pending approval, show delete button if therapist has credentials and signature image
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <button onClick={() => props.DeleteLog()} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                    else {
                        // Show warning message if therapist is missing credentials or signature image
                        return (
                            <button onClick={WarnUserMissingCredentials} className="bg-rose m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Delete</span></button>
                        )
                    }
                default:
                    // Do not show delete button for other status types
                    return <></>;
            }
        }

        // Do not show delete button if the user is not the author of the log
        return <></>;
    }

    const EditLogButton = () => {
        if (props.Log.billingDate) {

            // Only show the edit button if the current user is the author, or if current user is a BCBA
            if (globalContext.CoreAccount &&
                globalContext.CoreAccount.currentUser &&
                globalContext.CoreAccount.currentUser.therapistID &&
                (props.Log.therapistId == globalContext.CoreAccount.currentUser.therapistID || globalContext.CoreAccount.currentUser.bcba)) {

                switch (props.Log.dpnstatusId) {
                    case 1:
                        // If the log is approved, don't show the button
                        return (
                            <></>
                        )
                    case 2:
                        // If the log is pending correction, show the button if the user has the necessary credentials
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {

                            if (viewEditButtonLoading) {
                                return (
                                    <button disabled={true} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacblue-600">
                                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                                        <span className="drop-shadow align-middle pl-1">Loading</span>
                                    </button>
                                );
                            }
                            else {
                                return (<button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>)
                            }

                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                    case 3:
                        // If the log is imported, don't show the button
                        return (
                            <></>
                        )
                    case 4:
                        // If the log is created
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {

                            if (viewEditButtonLoading) {
                                return (
                                    <button disabled={true} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacblue-600">
                                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                                        <span className="drop-shadow align-middle pl-1">Loading</span>
                                    </button>
                                );
                            }
                            else {
                                return (<button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>)
                            }

                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                    case 6:
                        // If the log is incomplete
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                            if (viewEditButtonLoading) {
                                return (
                                    <button disabled={true} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacblue-600">
                                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                                        <span className="drop-shadow align-middle pl-1">Loading</span>
                                    </button>
                                );
                            }
                            else {
                                return (<button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>)
                            }
                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                    case 7:
                        // If the log is pending approval, show the button if the user has the necessary credentials
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {

                            if (viewEditButtonLoading) {
                                return (
                                    <button disabled={true} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out bg-lacblue-600">
                                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                                        <span className="drop-shadow align-middle pl-1">Loading</span>
                                    </button>
                                );
                            }
                            else {
                                return (<button onClick={NavigateToEditLog} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>)
                            }

                        }
                        else {
                            return (
                                <button onClick={WarnUserMissingCredentials} className="bg-lacblue m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Edit</span></button>
                            )
                        }
                    default:
                        return <></>;
                }

            }
            else {
                return <></>;
            }
        }
        return <></>;
    }

    const ApproveLogButton = () => {
        if (props.Log.billingDate) {

            //approve button
            switch (props.Log.dpnstatusId) {
                case 1:
                    //approved, show unapprove button if not harvested
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                <button onClick={() => props.UnapproveLog(props.Log)} className={"bg-pink m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-pink-600 " + (props.Log.harvested === true ? "hidden" : "")}><span className="drop-shadow">Unapprove</span></button>
                            </ProtectedComponent>
                        )
                    }
                    else {
                        return (
                            <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                <button onClick={WarnUserMissingCredentials} className={"bg-pink m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-pink-600 " + (props.Log.harvested === true ? "hidden" : "")}><span className="drop-shadow">Unapprove</span></button>
                            </ProtectedComponent>
                        )
                    }
                case 3:
                    //imported
                    return (
                        <></>
                    )
                case 4:
                    if (props.Log.showApproveButton) {
                        //created

                        // make sure lockout is after current center time
                        if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                            return (
                                <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                    <button onClick={() => props.ApproveLog(props.Log)} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                                </ProtectedComponent>
                            )
                        }
                        else {
                            return (
                                <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                    <button onClick={WarnUserMissingCredentials} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                                </ProtectedComponent>
                            )
                        }
                           
                    }
                    else
                    {
                        return (
                            <></>
                        )
                    }
                case 7:
                    //pending approval
                    if (globalContext.CoreAccount.therapistData?.credentials && globalContext.CoreAccount.therapistData?.signatureImage) {
                        return (
                            <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                <button onClick={() => props.ApproveLog(props.Log)} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                            </ProtectedComponent>
                        )
                    }
                    else {
                        return (
                            <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                                <button onClick={WarnUserMissingCredentials} className="bg-lacgreen m-auto w-[6rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approve</span></button>
                            </ProtectedComponent>
                        )
                    }
                default:
                    return <></>;
            }
            
            
        }

        return <></>;
    }

    const Comments = () => {
        if (isExpanded && props.Log.comments && props.Log.comments.length > 0) {
            return (
                <div className="mt-4 w-full">
                    <span className="font-bold">Comments:</span>
                    <table className="border-separate border-spacing-3">
                        <tbody>
                            {props.Log.comments ? props.Log.comments.map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy H:mm a') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return <></>
        }
    }

    if (props.Log.absent == false) {
        return (

            <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 max-w-[47rem] w-full xl:w-fit">

                <div className="grid-cols md:flex h-fit">

                    <div className="grid w-[10rem] h-fit">
                        <ExpandButton />

                        <Status />
                    </div>

                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Learner:</span>
                            {props.Log.clientName && <span>{props.Log.clientName}</span>}
                        </div>

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Clinician:</span>
                            {props.Log.therapistId && props.Therapists && <span>{GetTherapistName(props.Log.therapistId)}</span>}
                        </div>

                    </div>

                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Service Date:</span>
                            {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}
                        </div>

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Time In:</span>
                            {props.Log.startTime && <span>{new Date(props.Log.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                        </div>

                    </div>


                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">
                        <div className="grid h-fit my-auto">
                            <span className="font-bold">CPT Code:</span>
                            {props.Log.cptcode && <span>{props.Log.cptcode}</span>}
                        </div>

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Time Out:</span>
                            {props.Log.endTime && <span>{new Date(props.Log.endTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>}
                        </div>
                    </div>

                    <div className={"grid gap-3 place-content-center w-[7rem] h-fit mx-auto"}>

                        <div className="h-[2.25rem]">
                            <ApproveLogButton />
                        </div>

                        <div className="h-[2.25rem]">
                            <ViewLogButton/>
                        </div>

                        <div className="h-[2.25rem]">
                            <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                                <EditLogButton />
                            </ProtectedComponent>
                        </div>

                        <div className="h-[2.25rem]">
                            <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                                <DeleteLogButton />
                            </ProtectedComponent>
                        </div>


                    </div>

                </div>

                <Narrative />

                <Comments />

            </div>
        )
    }
    else {
        return (

            <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 max-w-[47rem] w-full xl:w-fit">

                <div className="grid-cols md:flex h-fit">

                    <div className="grid w-[10rem] h-fit">
                        <ExpandButton />

                        <Status />
                    </div>

                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Learner:</span>
                            {props.Log.clientName && <span>{props.Log.clientName}</span>}
                        </div>

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Clinician:</span>
                            {props.Log.therapistId && props.Therapists && <span>{GetTherapistName(props.Log.therapistId)}</span>}
                        </div>

                    </div>

                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Service Date:</span>
                            {props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}
                        </div>

                        <div className="grid h-fit my-auto">
                            <span className="font-bold">Absent Reason:</span>
                            {props.Log.billingDate && <span>{edpnContext.absentReasons && edpnContext.absentReasons.find(x => x.id == props.Log.absentReasonId)?.tardyReason}</span>}
                        </div>
                     

                    </div>

                    <div className="flex flex-col justify-between w-[10rem] py-1 mx-auto">

                    </div>
                 

                    <div className={"grid gap-3 place-content-center w-[7rem] h-fit mx-auto"}>

                        <div className="h-[2.25rem]">
                            <ApproveLogButton />
                        </div>

                        <div className="h-[2.25rem]">
                            <ViewLogButton/>
                        </div>

                        <div className="h-[2.25rem]">
                            <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                                <EditLogButton />
                            </ProtectedComponent>
                        </div>

                        <div className="h-[2.25rem]">
                            <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                                <DeleteLogButton />
                            </ProtectedComponent>
                        </div>


                    </div>

                </div>

                <Narrative />

                <Comments />

            </div>
        )
    }
    
    
    
};

