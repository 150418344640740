import { useContext, useEffect, useState } from "react";
import { ECLContext } from "../../pages/ecl/ECLContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { ECLAnswer, ECLAnswerObject, ECLQuestion } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import Select from "react-select";

interface Props {
    ECLQuestion: ECLQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    answerObject: ECLAnswerObject | null;
}

interface ChoiceOption {
    value: string,
    label: string
}

export const ECLQuestionTypeChoice: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const eclContext = useContext(ECLContext);

    const [selectedOption, setSelectedOption] = useState<ChoiceOption | null>();

    const [options, setOptions] = useState<ChoiceOption[]>();

    useEffect(() => {
        if (globalContext.CoreAccount.token && props.ECLQuestion) {
            UpdateOptions();
        }
    }, [globalContext.CoreAccount.token, props.ECLQuestion]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.ECLQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedOption) {
                        tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.choiceValue = Number(selectedOption.value));
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.ECLQuestion.questionId).forEach(x => x.choiceValue = -1);
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: ECLAnswer = {
                    answerId: null,
                    questionId: props.ECLQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: selectedOption ? Number(selectedOption.value) : -1,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedOption, props.answerObject?.answers]);


    function UpdateOptions() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/ECL/GetChoices?questionid=" + props.ECLQuestion?.questionId;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setOptions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/GetChoices" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    //auto populate rbt
    useEffect(() => {
        if (eclContext.selectedEditAnswer && selectedOption == null && options) {
            if (eclContext.selectedEditAnswer && props.ECLQuestion) {
                var tempanswer = eclContext.selectedEditAnswer.answers?.find(x => x.questionId == props.ECLQuestion.questionId);
                if (tempanswer && tempanswer.choiceValue) {
                    var temp = options.find(x => x.value == String(tempanswer?.choiceValue));
                    if (temp) {
                        setSelectedOption(temp);
                    }
                }
            }
        }

    }, [eclContext.selectedEditAnswer, props.ECLQuestion, options]);
    
    return (
        <div className="w-full m-auto mt-4">
            <label className="block font-bold">
                {props.ECLQuestion.questionText}:
            </label>
            <Select isDisabled={eclContext.isViewOnly} options={options} onChange={setSelectedOption} value={selectedOption} />
        </div>
    )

};

