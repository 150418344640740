import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutcomesContext } from "../../pages/outcomes/OutcomesContext";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { Therapist, Center, CredentialsTherapistPayor, VwPayorsPerCenter, TherapistCredentialNote } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../ProfilePicture";
import { GlobalContext } from "../../utils/GlobalContext";
import { ModalNotification } from "../ModalNotification";
import Select, { MultiValue } from "react-select";
import { text } from "@fortawesome/fontawesome-svg-core";


interface Props {
    therapistNotes: TherapistCredentialNote[] | undefined,
    UpdateNotes: any;
}


export const CredentialsTherapistNotesCard: React.FC<Props> = (props) => {

    const credentialsContext = useContext(CredentialsContext);
    const globalContext = useContext(GlobalContext);

    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            props.UpdateNotes();
        }
    }, [globalContext.CoreAccount.token]);

    function OpenExistingNote(note: TherapistCredentialNote) {

        var textAreaDom = (document.getElementById("therapistNote") as HTMLInputElement);
        if (textAreaDom) {
            textAreaDom.value = note.notes;
        }

        credentialsContext.setNewCenterCredentialsCardActive(false);
        credentialsContext.setSelectedTherapistNote(note);
        credentialsContext.setTherapistNoteModalActive(true);
    }

    function AddTherapistNote() {
        var textAreaDom = (document.getElementById("therapistNote") as HTMLInputElement);
        if (textAreaDom) {
            textAreaDom.value = "";
        }

        credentialsContext.setSelectedTherapistNote(null);
        credentialsContext.setTherapistNoteModalActive(true);
    }

    return (

        <div key={credentialsContext.selectedTherapist?.therapistId} className=" grid w-full md:w-[38rem] w-[28rem] h-[12rem] overflow-y-scroll mb-10 p-5 bg-gray-50 border drop-shadow rounded flex">

            <div className="grid grid-cols-2">
                <div>
                    <span className="text-lg font-bold">Notes</span>
                </div>
                <div>
                    <FontAwesomeIcon className="float-right text-lacblue w-7 h-7 m-auto hover:cursor-pointer" icon={solid('plus')} onClick={() => AddTherapistNote()} ></FontAwesomeIcon>
                </div>
                
            </div>

            <table className="table-auto">
                <thead className="border-b dark:border-lacblue-500">
                    <tr className="m-auto w-full">
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {props.therapistNotes ? props.therapistNotes.map(note => (
                        <tr key={note.noteId} className="border-b dark:border-lacblue-500 hover:bg-gray-100 hover:cursor-pointer" onClick={() => OpenExistingNote(note)}>
                            <td className="px-10 border-r-2 dark:border-lacblue-500">{note.statusDescription}</td>
                            <td className="px-10 dark:border-lacblue-500">{new Date(note.noteDate).toDateString()}</td>
                        </tr>
                    )) : <></>}

                </tbody>

            </table>
        </div>
    )

}