import { Routes, Route } from "react-router-dom";

//MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

//page imports
import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { ECLHome } from "./pages/ecl/ECLHome";
import { ECLLogs } from "./pages/ecl/ECLLogs";
import { GlobalContextProvider } from './utils/GlobalContext';
import { ECLAddLog } from "./pages/ecl/ECLAddLog";
import { ECLViewLog } from "./pages/ecl/ECLViewLog";
import { EDPNContextProvider } from "./pages/edpn/EDPNContext";

import { ECLContextProvider } from "./pages/ecl/ECLContext";
import { ECLViewLogs } from "./pages/ecl/ECLViewLogs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ECLEditLog } from "./pages/ecl/ECLEditLog";
import { ECLApprovals } from "./pages/ecl/ECLApprovals";
import { OutcomesHome } from "./pages/outcomes/OutcomesHome";
import { OutcomesContextProvider } from "./pages/outcomes/OutcomesContext";
import { OutcomesViewAssessments } from "./pages/outcomes/OutcomesViewAssessments";
import { OutcomesAddAssessment } from "./pages/outcomes/OutcomesAddAssessment";
import { CredentialsHome } from "./pages/credentials/Home";
import { CredentialsBCBAAdmin } from "./pages/credentials/BCBAadmin";
import { BCBACredentials } from "./pages/credentials/BCBACredentials";
import { PayorAdministration } from "./pages/credentials/PayorAdministration";
import { CredentialsContextProvider } from "./pages/credentials/CredentialsContext";
import { CenterAdministration } from "./pages/credentials/CenterAdministration";
import { EDPNViewLog } from "./pages/edpn/EDPNViewLog";
import { EDPNEditLog } from "./pages/edpn/EDPNEditLog";
import { EDPNViewLogs } from "./pages/edpn/EDPNViewLogs";
import { EDPNAddLog } from "./pages/edpn/EDPNAddLog";
import { EDPNHome } from "./pages/edpn/EDPNHome";
import { EDPNApprovals } from "./pages/edpn/EDPNApprovals";
import { EDPNApprovals2 } from "./pages/edpn/EDPNApprovals2";
import { EDPNLearnerInfo } from "./pages/edpn/EDPNLearnerInfo";
import { EDPNAddAbsent } from "./pages/edpn/EDPNAddAbsent";
import { EDPNEditAbsent } from "./pages/edpn/EDPNEditAbsent";
import { EDPNViewAbsent } from "./pages/edpn/EDPNViewAbsent";
import { ECLLearnerInfo } from "./pages/ecl/ECLLearnerInfo";
import { EDPNGroupCreate } from "./pages/edpn/EDPNGroupCreate";
import { LearnerEdit } from "./pages/credentials/LearnerEdit";



type AppProps = {
    pca: IPublicClientApplication
};

function App({ pca }: AppProps) {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MsalProvider instance={pca}>
                <GlobalContextProvider>
                    <PageLayout>
                        <Pages />
                    </PageLayout>
                </GlobalContextProvider>
            </MsalProvider>
        </LocalizationProvider>
    );
}
  
function Pages() {
    return (
        <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/" element={<Home />} />

            <Route path="/ecl/logs/viewlogs/viewlog" element={<ECLContextProvider><CredentialsContextProvider><ECLViewLog /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/logs/viewlogs/editlog" element={<ECLContextProvider><CredentialsContextProvider><ECLEditLog /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/logs/viewlogs" element={<ECLContextProvider><CredentialsContextProvider><ECLViewLogs /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/logs/addlog" element={<ECLContextProvider><CredentialsContextProvider><ECLAddLog /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/logs" element={<ECLContextProvider><CredentialsContextProvider><ECLLogs /></CredentialsContextProvider></ECLContextProvider>} />

            <Route path="/ecl/approvals/viewlog" element={<ECLContextProvider><CredentialsContextProvider><ECLViewLog /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/approvals/editlog" element={<ECLContextProvider><CredentialsContextProvider><ECLEditLog /></CredentialsContextProvider></ECLContextProvider>} />
            <Route path="/ecl/approvals" element={<ECLContextProvider><ECLApprovals /></ECLContextProvider>} />

            <Route path="/ecl/learnerinfo" element={<ECLContextProvider><ECLLearnerInfo /></ECLContextProvider>} />

            <Route path="/ecl" element={<ECLContextProvider><ECLHome /></ECLContextProvider>} />

            <Route path="/outcomes" element={<OutcomesContextProvider><OutcomesHome /></OutcomesContextProvider>} />
            <Route path="/outcomes/evaluations" element={<OutcomesContextProvider><OutcomesViewAssessments /></OutcomesContextProvider>} />
            <Route path="/outcomes/evaluations/addevaluation" element={<OutcomesContextProvider><OutcomesAddAssessment /></OutcomesContextProvider>} />

            <Route path="/credentials" element={<CredentialsContextProvider><CredentialsHome></CredentialsHome></CredentialsContextProvider>} />
            <Route path="/credentials/bcbaadmin" element={<CredentialsContextProvider><CredentialsBCBAAdmin></CredentialsBCBAAdmin></CredentialsContextProvider>} />
            <Route path="/credentials/bcbacredentials" element={<CredentialsContextProvider><BCBACredentials></BCBACredentials></CredentialsContextProvider>} />
            <Route path="/credentials/PayorAdministration" element={<CredentialsContextProvider><PayorAdministration></PayorAdministration></CredentialsContextProvider>} />
            <Route path="/credentials/CenterAdministration" element={<CredentialsContextProvider><CenterAdministration></CenterAdministration></CredentialsContextProvider>} />
            <Route path="/credentials/learneredit" element={<CredentialsContextProvider><LearnerEdit /></CredentialsContextProvider>} />



            <Route path="/edpn/viewlogs/viewlog" element={<EDPNContextProvider><CredentialsContextProvider><EDPNViewLog /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/viewlogs/editlog" element={<EDPNContextProvider><CredentialsContextProvider><EDPNEditLog /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/viewlogs" element={<EDPNContextProvider><CredentialsContextProvider><EDPNViewLogs /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/addlog" element={<EDPNContextProvider><CredentialsContextProvider><EDPNAddLog /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/addabsent" element={<EDPNContextProvider><EDPNAddAbsent /></EDPNContextProvider>} />
            <Route path="/edpn/editabsent" element={<EDPNContextProvider><EDPNEditAbsent /></EDPNContextProvider>} />
            <Route path="/edpn/viewabsent" element={<EDPNContextProvider><EDPNViewAbsent /></EDPNContextProvider>} />

            <Route path="/edpn" element={<EDPNContextProvider><CredentialsContextProvider><EDPNHome /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/approvals/viewlog" element={<EDPNContextProvider><CredentialsContextProvider><EDPNViewLog /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/approvals/editlog" element={<EDPNContextProvider><CredentialsContextProvider><EDPNEditLog /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/approvals/editabsent" element={<EDPNContextProvider><EDPNEditAbsent /></EDPNContextProvider>} />
            <Route path="/edpn/approvals/viewabsent" element={<EDPNContextProvider><EDPNViewAbsent /></EDPNContextProvider>} />
            <Route path="/edpn/approvals" element={<EDPNContextProvider><EDPNApprovals /></EDPNContextProvider>} />
            <Route path="/edpn/approvals2" element={<EDPNContextProvider><CredentialsContextProvider> <EDPNApprovals2 /></CredentialsContextProvider></EDPNContextProvider>} />
            <Route path="/edpn/learnerinfo" element={<EDPNContextProvider><EDPNLearnerInfo /></EDPNContextProvider>} />
            <Route path="/edpn/groupcreate" element={<EDPNContextProvider><EDPNGroupCreate /></EDPNContextProvider>} />



            <Route path="/profile" element={<Profile />} />

        </Routes>
    )
}

export default App;
