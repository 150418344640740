// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutcomesContext } from "./OutcomesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, Client, ECLAnswerObject, ECLSet, Therapist, TherapistOption, VwOutcomesEvaluation } from "../../utils/Definitions";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import Select, { MultiValue } from "react-select";
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";
import { isAfter, sub } from "date-fns";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { OutcomesEvaluationCard } from "../../components/outcomes/OutcomesEvaluationCard";


//only renders if authenticated
const OutcomesViewAssessmentsContent = () => {

    const navigate = useNavigate();

    const outcomesContext = useContext(OutcomesContext);
    const globalContext = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState<boolean>(true);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    const [outcomeEvaluations, setOutcomeEvaluations] = useState<VwOutcomesEvaluation[] | null>(null);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }


    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    useEffect(() => {
        if (!outcomesContext.selectedClient) {
            navigate("/outcomes");
        }
    })

    function NavigateBack() {
        navigate(-1);
    }

    function NavigateAddInitialAssessment() {
        outcomesContext.setSelectedEvaluationForEdit(null);
        outcomesContext.setSelectedEvaluationType(1);
        navigate("/outcomes/evaluations/addevaluation");
    }

    function NavigateAddOngoingAssessment() {
        outcomesContext.setSelectedEvaluationForEdit(null);
        outcomesContext.setSelectedEvaluationType(2);
        navigate("/outcomes/evaluations/addevaluation");
    }

    function NavigateAddEndAssessment() {
        outcomesContext.setSelectedEvaluationForEdit(null);
        outcomesContext.setSelectedEvaluationType(3);
        navigate("/outcomes/evaluations/addevaluation");
    }

    function NavigateEditAssessment(VwOutcomesEvaluation: VwOutcomesEvaluation) {
        outcomesContext.setSelectedEvaluationForEdit(VwOutcomesEvaluation);
        
        outcomesContext.setSelectedEvaluationType(VwOutcomesEvaluation.evaluationTypeId);
        navigate("/outcomes/evaluations/addevaluation");
    }


    //initial load
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            setPageLoading(false);
        }
    }, [globalContext.CoreAccount.token]);

    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            GetEvaluations();
        }
    }, [globalContext.CoreAccount.token]);
   
    function GetEvaluations() {

        if (outcomesContext.selectedClient) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Outcomes/GetListOfOutcomeEvaluation?clientID=" + outcomesContext.selectedClient?.clientId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("VwOutcomesEvaluation:", result);
                            var tempevals: VwOutcomesEvaluation[] | null = result;
                            if (tempevals) {
                                tempevals.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
                                setOutcomeEvaluations(result);
                            }


                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Outcomes/GetListOfOutcomeEvaluation" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    if (outcomesContext.selectedClient && !pageLoading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div onClick={NavigateBack} className="my-2 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Outcomes Home</span>
                        <div className="flex-1"></div>
                    </div>


                    <div className="grid grid-flow-row">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:w-fit xl:m-auto">

                            <div className="grid grid-cols-1 xl:grid-cols-3 h-fit mt-0 border rounded bg-gray-50 mx-auto">

                                <div className="grid mt-4 mb-4">
                                    <ProfilePicture isEditable={false} Client={outcomesContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />
                                </div>


                                <table className="mt-4 xl:my-8">
                                    <tbody>
                                        <tr>
                                            <td><span className="text-2xl font-bold">{outcomesContext.selectedClient.fullName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Birthday:</span></td>
                                            <td><span className="text-bold ml-2">{new Date(outcomesContext.selectedClient.dob).toLocaleDateString()}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Insurance Provider:</span></td>
                                            <td><span className="text-bold ml-2">{outcomesContext.selectedClient.insuranceName}</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="grid grid-cols-1 w-fit gap-4 p-4">

                                    <ProtectedComponent RequiredRoles={["Outcomes.Write"]} >
                                        <button onClick={NavigateAddInitialAssessment} className="bg-lacgreen w-[15rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 flex-grow-0 mr-2">
                                            <span className="drop-shadow">Initial Evaluation</span>
                                        </button>
                                    </ProtectedComponent>

                                    <ProtectedComponent RequiredRoles={["Outcomes.Write"]} >
                                        <button onClick={NavigateAddOngoingAssessment} className="bg-lacblue w-[15rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 flex-grow-0 mr-2">
                                            <span className="drop-shadow">Ongoing Evaluation</span>
                                        </button>
                                    </ProtectedComponent>

                                    <ProtectedComponent RequiredRoles={["Outcomes.Write"]} >
                                        <button onClick={NavigateAddEndAssessment} className="bg-rose w-[15rem] h-[4rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                                            <span className="drop-shadow">Transition & Discharge Evaluation</span>
                                        </button>
                                    </ProtectedComponent>

                                </div>
                                
                            </div>
                        </div>

                        <div className="w-fit mx-auto">
                            {outcomeEvaluations ? outcomeEvaluations.map(evaluation => <OutcomesEvaluationCard NavigateEditAssessment={NavigateEditAssessment} OutcomesEvaluation={evaluation} key={evaluation.outcomesEvaluationsId} />) : <></>}
                        </div>

                    </div>
               
                </div>


                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />


            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function OutcomesViewAssessments() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Outcomes.Read', 'Outcomes.Write']}>
                    <OutcomesViewAssessmentsContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};