import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion, TherapistOption } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import { MultiValue, OnChangeValue } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "react-select";

interface Props {
    EDPNQuestion: EDPNQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;

}

interface ChoiceOption {
    value: string,
    label: string
}

export const EDPNQuestionTypeMultichoice: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [selectedOptions, setSelectedOptions] = useState<ChoiceOption[] | null>();

    const [options, setOptions] = useState<ChoiceOption[]>();

    useEffect(() => {
        if (globalContext.CoreAccount.token && props.EDPNQuestion) {
            UpdateOptions();
        }
    }, [globalContext.CoreAccount.token, props.EDPNQuestion]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedOptions) {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = selectedOptions.map(z => z.label).join(","));
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = "");
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedOptions]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (!answer)
            {
                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: selectedOptions ? selectedOptions?.map(x => x.label).join(",") : "",
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }

    }, [selectedOptions, props.answerObject]);


    function UpdateOptions() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/EDPN/GetChoices?questionid=" + props.EDPNQuestion?.questionId;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setOptions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetChoices" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }



    //auto populate
    useEffect(() => {
        if (edpnContext.selectedEditAnswer && selectedOptions == null && options) {
            if (edpnContext.selectedEditAnswer && props.EDPNQuestion) {


                var answer: EDPNAnswer | undefined = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == props.EDPNQuestion.questionId);
                if (answer?.textValue?.trim()) {

                    var foundAnswers: string[] | undefined = answer.textValue?.split(",");

                    if (foundAnswers) {

                        var populatedChoices: ChoiceOption[] = [];

                        var highestValue = options.reduce((prev, current) => {
                            return (current.value > prev.value) ? current : prev;
                        }, options[0]).value; // Initialize with the first object in the array

                        //create choices from found answers
                        foundAnswers.forEach(foundAnswer => {

                            var foundOption = options.find(x => x.label == foundAnswer);

                            if (foundOption) {
                                //add existing option to selected choices
                                populatedChoices.push(foundOption);
                            }
                            else {
                                //custom option, create new option
                                highestValue += 1;

                                var newChoice: ChoiceOption = {
                                    value: highestValue,
                                    label: foundAnswer
                                };
                                populatedChoices.push(newChoice);
                            }
                        })

                        setSelectedOptions(populatedChoices);
                    }
                }
            }
        }

    }, [edpnContext.selectedEditAnswer, props.EDPNQuestion, options]);

    

    function OnChange(input: MultiValue<TherapistOption>) {
        var finalOptions: TherapistOption[] = [];

        if (options) {
            input.forEach(x => {
                var foundOption = options.find(z => z.value == x.value);
                if (foundOption) {
                    finalOptions.push(foundOption);
                }
                else {

                    var CustomChoice: ChoiceOption = {
                        value: x.value,
                        label: x.label
                    };

                    finalOptions.push(CustomChoice);
                }
            })
        }

        setSelectedOptions(finalOptions);
    }

    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto mb-1 col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>
                <Select
                    isSearchable={false}
                    className="col-span-12"
                    onBlur={() => edpnContext.setAutosaveFlag(true)}
                    onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnChange(selectedOptions)}
                    isMulti={true}
                    isDisabled={edpnContext.isViewOnly}
                    options={options}
                    closeMenuOnSelect={false}
                    value={selectedOptions} />
            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-12">
                <label className="block font-bold">
                    {props.EDPNQuestion.questionText}:
                </label>
                <Select
                    isSearchable={false}
                    onBlur={() => edpnContext.setAutosaveFlag(true)}
                    onChange={(selectedOptions: OnChangeValue<TherapistOption, true>) => OnChange(selectedOptions)}
                    isMulti={true}
                    isDisabled={edpnContext.isViewOnly}
                    options={options}
                    closeMenuOnSelect={false}
                    value={selectedOptions} />
            </div>
        )
    }

};

