import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";
import { CredentialsContext } from "./CredentialsContext";
/*import toast, { Toaster } from 'react-hot-toast';*/

// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, RbtcredentialState, VwPayorsPerCenter } from "../../utils/Definitions";
import Select, { MultiValue } from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";



const PayorAdministrationContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);
    const credentialsContext = useContext(CredentialsContext);

    const [centers, setCenters] = useState<Center[]>();
    const [payors, setPayors] = useState<VwPayorsPerCenter[]>();
    const [rbtStates, setRBTStates] = useState<RbtcredentialState[]>();

    const [pageLoading, setPageLoading] = useState<boolean>(false);

    function NavigateBack() {
        navigate(-1);
    }


    function UpdateCenters() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawcenters:", result);
                        setCenters(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateStates() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Credentials/GetRBTCredentialStates", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawStates:", result);
                        setRBTStates(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetRBTCredentialStates" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function SaveState(rbtState: RbtcredentialState) {

        if (credentialsContext.selectedCenter) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "POST",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/SaveStateForRBTCredentials?state=" + rbtState.state + "&active=" + !rbtState.active, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("state Saved status: ", result);
                            UpdateStates();
                        },
                        (error) => {
                            setPageLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/SaveStateForRBTCredentials" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function UpdatePayors() {

        if (credentialsContext.selectedCenter) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/GetPayorsForCenter?centerId=" + credentialsContext.selectedCenter?.centerId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("rawPayors:", result);
                            setPayors(result);
                        },
                        (error) => {
                            setPageLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetPayors" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function UpdatePayorToCenter(payor: VwPayorsPerCenter) {

        if (credentialsContext.selectedCenter) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(payor)
                };

                fetch(apiConfig.apiEndpoint + "/Credentials/UpdatePayorToCenter", options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("update status:", result);
                            UpdatePayors();
                            //SaveSuccess();
                        },
                        (error) => {
                            setPageLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/UpdatePayorToCenter" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateStates();
        }
    }, [globalContext.CoreAccount.token]);

    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateCenters();
        }
    }, [globalContext.CoreAccount.token]);

    //update payors
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdatePayors();
        }
    }, [globalContext.CoreAccount.token, credentialsContext.selectedCenter]);

    useEffect(() => {
        //select first center if there is no selected center
        console.log("Before if: ", credentialsContext.selectedCenter);
        if (centers && centers.length > 0 && !credentialsContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    credentialsContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    credentialsContext.setSelectedCenter(centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                credentialsContext.setSelectedCenter(centers[0]);
            }

        }

    }, [centers]);

    if (!pageLoading) {
        return (

            
            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div onClick={NavigateBack} className="my-2 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Credentials Home</span>
                        <div className="flex-1"></div>
                    </div>

                    <div className="grid grid-flow-row grid-cols-1">

                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:w-fit xl:m-auto">

                            <div className="grid grid-cols-1 h-fit w-800 p-5 border rounded bg-gray-50 mx-auto">

                                <div className="w-full xl:w-[16rem] m-auto">
                                    <label className="block font-bold" htmlFor="pods">
                                        Center:
                                    </label>
                                    <Select options={centers} onChange={credentialsContext.setSelectedCenter} value={credentialsContext.selectedCenter} />
                                </div>

                                <table className="table-auto">
                                    <thead className="border-b dark:border-lacblue-500">
                                        <tr className="m-auto w-full">
                                            <th>Payor</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payors ? payors.map(payor => (
                                            <tr key={payor.payorId} className="border-b dark:border-lacblue-500">
                                                <td className="px-10 border-r-2 dark:border-lacblue-500">{payor.payorName}</td>
                                                <td className="px-10">
                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" className="sr-only peer" onChange={event => UpdatePayorToCenter(payor)} checked={payor.statusId === 1} />
                                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </td>
                                            </tr>
                                        )) : <></>}
                                        
                                    </tbody>
                                </table>

                            </div>

                            <div className="grid grid-cols-1 h-fit w-800 p-5 border rounded bg-gray-50 mx-auto">

                                <div className="w-full xl:w-[16rem] m-auto mb-3">
                                    <label className="block font-bold text-base" htmlFor="pods">
                                        States Allowing Credentialed RBT
                                    </label>
                                </div>

                                <table className="table-auto">
                                    <thead className="border-b dark:border-lacblue-500">
                                        <tr className="m-auto w-full">
                                            <th className="px-10 items-center">State</th>
                                            <th className="px-10 items-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rbtStates ? rbtStates.map(state => (
                                            <tr key={state.state} className="border-b dark:border-lacblue-500">
                                                <td className="px-10 border-r-2 dark:border-lacblue-500">{state.state}</td>
                                                <td className="px-10">
                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" className="sr-only peer" onChange={event => SaveState(state)} checked={state.active === true} />
                                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </td>
                                            </tr>
                                        )) : <></>}

                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
    else {
        return (
            <div className= "w-full h-auto flex" >
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon = { solid('spinner') } />
            </div>
        );
    }
};

export function PayorAdministration() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>

            

            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Credentials.Read', 'Credentials.Write']}>
                    <PayorAdministrationContent />
                </ProtectedPage>

            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>

        </>
    );
}