// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { APIReturnObject, Center, Client, EDPNAnswerObject, EDPNSet, Status, Therapist, TherapistOption } from "../../utils/Definitions";
import { EDPNViewLogCard } from "../../components/edpn/EDPNViewLogCard";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import Select, { MultiValue } from "react-select";
import { EDPNApprovalCard } from "../../components/edpn/EDPNApprovalCard";
import { ValueContainer } from "../../components/ValueContainer";
import { ModalConfirm } from "../../components/ModalConfirm";
import { EDPNLogsList } from "../../components/edpn/EDPNLogsList";
import { isAfter, set, sub } from "date-fns";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { zonedTimeToUtc } from "date-fns-tz";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, TextFieldProps } from "@mui/material";


//only renders if authenticated
const EDPNViewLogsContent = () => {


    const navigate = useNavigate();


    const edpnContext = useContext(EDPNContext);
    const globalContext = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [logsLoading, setLogsLoading] = useState<boolean>(true);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }


    const [logs, setLogs] = useState<EDPNAnswerObject[]>([]);
    const [importedHistoryDate, setImportedHistoryDate] = useState<Date | null>(new Date());

    const [therapists, setTherapists] = useState<Therapist[] | null>(null);

    const [centers, setCenters] = useState<Center[]>();

    const [confirmUnapproveModalActive, setConfirmUnapproveModalActive] = useState<boolean>(false);
    const [confirmUnapproveModalMessage, setConfirmUnapproveModalMessage] = useState<string>("");

    const [confirmModalActive, setConfirmModalActive] = useState<boolean>(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState<string>("");

    const [confirmModalActiveLogs, setConfirmModalActiveLogs] = useState<boolean>(false);
    const [confirmModalMessageLogs, setConfirmModalMessageLogs] = useState<string>("");

    const [confirmModalActiveSetLogs, setConfirmModalActiveSetLogs] = useState<boolean>(false);
    const [confirmModalMessageSetLogs, setConfirmModalMessageSetLogs] = useState<string>("");

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [approveModalActive, setApproveModalActive] = useState<boolean>(false);
    const [approveMessage, setApproveMessage] = useState<string>("");

    const [logToApprove, setLogToApprove] = useState<EDPNAnswerObject | null>(null);
    const [logsToApprove, setLogsToApprove] = useState<EDPNAnswerObject[] | null>(null);

    const [edpnSetLogsToApprove, setEclSetLogsToApprove] = useState<EDPNAnswerObject[] | null>(null);

    const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
    const [confirmDeleteModalMessage, setConfirmDeleteModalMessage] = useState<string>("");
    const [logToDelete, setLogToDelete] = useState<EDPNAnswerObject | null>(null);

    const [logToHold, setLogToHold] = useState<EDPNAnswerObject | null>(null);
    const [confirmHoldModalActive, setConfirmHoldModalActive] = useState<boolean>(false);
    const [confirmHoldModalMessage, setConfirmHoldModalMessage] = useState<string>("");

    const [showImportedHistory, setShowImportedHistory] = useState<boolean>(false);

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [medicaidCheckModalActive, setMedicaidCheckModalActive] = useState<boolean>(false);



    function NavigateBack() {
        navigate(-1);
    }

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    // AUTO REFRESH EVERY MINUTE
    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log("silent refresh");
            UpdateLogs();

        }, (60 * 1000)); // 60 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function OnClickDeleteLog(EDPNLog: EDPNAnswerObject) {
        setLogToDelete(EDPNLog);
        setConfirmDeleteModalMessage("<div>Are you sure you want to delete the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmDeleteModalActive(true);
    }

    function DeleteLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToDelete) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/SoftDeleteLog?id=" + logToDelete.logId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot delete log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to delete log. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                globalContext.setErrorModalActive(true);
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/SoftDeleteLog" });
                            globalContext.setErrorModalActive(true);
                        }

                    )
            }

        })

    }

    function OnClickApproveLog(EDPNLog: EDPNAnswerObject) {
        setLogToApprove(EDPNLog);
        setConfirmModalMessage("<div>Are you sure you want to approve the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmModalActive(true);
    }
    function OnClickUnapproveLog(EDPNLog: EDPNAnswerObject) {
        setLogToApprove(EDPNLog);
        setConfirmUnapproveModalMessage("<div>Are you sure you want to Unapprove the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmUnapproveModalActive(true);
    }

    function OnClickApproveAllLogs() {
        var tempMessage: string = "<div>Are you sure you want to approve the following logs?</div>";

        var tempLogsToApprove: EDPNAnswerObject[] = logs.filter(x => (x.dpnstatusId == 4) &&
            !(x.cptcode == '97151' && x.isMimedicaid == 1) &&
            x.showApproveButton);

        if (tempLogsToApprove.length > 0) {
            tempLogsToApprove.forEach((log) => {
                tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
            })

            setLogsToApprove(tempLogsToApprove);

        }
        else {
            tempMessage = "<div>No approveable logs detected.</div>";
        }
        
        setConfirmModalMessageLogs(tempMessage);
        setConfirmModalActiveLogs(true);
    }

    async function ApprovalValid(token: string, edpnanswerobjects: EDPNAnswerObject[] | null) {

        if (edpnanswerobjects && edpnanswerobjects.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(edpnanswerobjects)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/EDPN/ApprovalLogCheck", options);
            var errorText: string = await response.json();

            if (errorText.length > 0) {
                //error!
                setApproveMessage(errorText);
                setApproveModalActive(true);

                return errorText;
            }
            else {
                //no error, continue
                return errorText;
            }
        }
        else {
            return "";
        }
    }

    function ApproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {

                //check if approval is valid
                var templogs: EDPNAnswerObject[] = [];
                templogs.push(logToApprove);
                ApprovalValid(token, templogs).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        var headers = new Headers();

                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        var options = {
                            method: "GET",
                            headers: headers
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/ApproveLog?logid=" + logToApprove.logId;

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLog" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }

                })
            }

        })

    }

    function UnapproveLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToApprove) {


                if (errorMessage.length == 0) {
                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/EDPN/UnapproveLog?logid=" + logToApprove.logId;

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result: APIReturnObject) => {
                                if (result.status === Status.SUCCESS) {
                                    setLogsLoading(true);
                                    UpdateLogs();
                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot unapprove log. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setLogsLoading(true);
                                    UpdateLogs();
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to unapprove log. " + result.message, errorLocation: "/EDPN/ApproveLog" });
                                    globalContext.setErrorModalActive(true);
                                    setLogsLoading(true);
                                    UpdateLogs();
                                }
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UnapproveLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        )
                }

            }

        })

    }

    function ApproveLogs() {
        globalContext.GetToken().then((token: any) => {
            if (logsToApprove) {

                //check if approval is valid
                ApprovalValid(token, logsToApprove).then((errorMessage) => {

                    if (errorMessage.length == 0) {
                        //add log to database
                        var headers = new Headers();
                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        headers.append("Content-type", "application/json;charset=UTF-8");

                        var logsid = logsToApprove.map(x => x.logId).join(',');

                        var options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(logsid)
                        };

                        var url = apiConfig.apiEndpoint + "/EDPN/ApproveLogs";

                        fetch(url, options)
                            .then(res => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    if (result.status === Status.SUCCESS) {
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLogs" });
                                        globalContext.setErrorModalActive(true);
                                        setLogsLoading(true);
                                        UpdateLogs();
                                    }
                                },
                                (error) => {
                                    globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLogs" });
                                    globalContext.setErrorModalActive(true);
                                }
                            )
                    }
                })
            }

        })
    }

    function OnClickApproveSet(setid: number) {

        var tempSetLogsToApprove: EDPNAnswerObject[] = logs.filter(x => x.isMimedicaid == 1 && x.logSetId == setid);


        if (tempSetLogsToApprove.length > 0) {
            //michigan medicaid check
            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");


                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/MIMedicaidApproveSetValidCheck?clientID=" + tempSetLogsToApprove[0].clientId;

                console.log("URL:", url);

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {

                                var tempMessage: string = "<div>Are you sure you want to approve the following set?</div>";
                                tempSetLogsToApprove.forEach((log) => {
                                    tempMessage += "<div>" + log.clientName + " on " + (log.billingDate ? new Date(log.billingDate).toLocaleDateString() : "") + "</div>";
                                })


                                setEclSetLogsToApprove(tempSetLogsToApprove);
                                setConfirmModalMessageSetLogs(tempMessage);
                                setConfirmModalActiveSetLogs(true);

                            }
                            else {
                                setMedicaidCheckModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/MIMedicaidApproveSetValidCheck" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    function ApproveSet() {
        globalContext.GetToken().then((token: any) => {

            console.log("edpnSetLogsToApprove:", edpnSetLogsToApprove);

            if (edpnSetLogsToApprove) {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var logsid = edpnSetLogsToApprove.map(x => x.logId).join(',');

                console.log("set:", logsid);

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(logsid)
                };

                var url = apiConfig.apiEndpoint + "/EDPN/ApproveLogs";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot approve. " + result.message);
                                globalContext.setNotificationModalActive(true);
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to approve. " + result.message, errorLocation: "/EDPN/ApproveLogs" });
                                globalContext.setErrorModalActive(true);
                                setLogsLoading(true);
                                UpdateLogs();
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/ApproveLogs" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function OnClickHoldLog(EDPNLog: EDPNAnswerObject) {
        setLogToHold(EDPNLog);
        setConfirmHoldModalMessage("<div>Are you sure you want to hold the log for:</div><div>" + EDPNLog.clientName + " on " + (EDPNLog.billingDate ? new Date(EDPNLog.billingDate).toLocaleDateString() : "") + "?</div>");
        setConfirmHoldModalActive(true);
    }

    function HoldLog() {
        globalContext.GetToken().then((token: any) => {
            if (logToHold) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/HoldLog?id=" + logToHold.logId + "&author=" + globalContext.CoreAccount.name;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                setLogsLoading(true);
                                UpdateLogs();
                                console.log("success, updating logs");
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to hold log. Unknown reason.", errorLocation: "/EDPN/HoldLog" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/HoldLog" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })

    }


    //initial load
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            setLogsLoading(true);
            UpdateLogs();
            UpdateTherapists();
        }
    }, [globalContext.CoreAccount.token]);

    //auto select center
    useEffect(() => {
        //select first center if there is no selected center
        if (centers && centers.length > 0 && !edpnContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    edpnContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    edpnContext.setSelectedCenter(centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                edpnContext.setSelectedCenter(centers[0]);
            }

        }

    }, [centers]);

    //upon center choice change, update logs
    useEffect(() => {

        if (edpnContext.selectedCenter && importedHistoryDate) {
            setLogsLoading(true);
            UpdateLogs();
        }
    }, [edpnContext.selectedCenter, showImportedHistory, importedHistoryDate]);


    useEffect(() => {
        setLogsLoading(false);
    }, [logs]);
    

    function UpdateLogs() {
        globalContext.GetToken().then((token: any) => {
            if (edpnContext.selectedCenter && edpnContext.selectedCenter.centerId) {

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetLogs?id=" + edpnContext.selectedClient?.clientId + "&imported=" + showImportedHistory + "&monthday=" + importedHistoryDate?.toDateString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setLogs(result);
                            setPageLoading(false);
                        },
                        (error) => {
                            setPageLoading(false);
                            setLogsLoading(false);
                            //globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLogs" });
                            //globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function UpdateTherapists() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllTherapists";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapists(result);
                    },
                    (error) => {
                        setPageLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }


    const handleDateChange = (date: Date | null) => {
        // Extract the month from the selected date
        setImportedHistoryDate(date);
    };
   

    if (logs && edpnContext.selectedClient && !pageLoading) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to EDPN Home</span>
                        <div className="flex-1"></div>
                    </div>


                    <div className="grid grid-flow-row">



                        <div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:w-fit xl:m-auto">

                            <div className="grid grid-cols-2 h-fit mt-0 mb-8 w-fit mx-auto">

                                <ProfilePicture isEditable={false} Client={edpnContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />

                                <table>
                                    <tbody>
                                        <tr>
                                            <td><span className="font-bold">Learner Name:</span></td>
                                            <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Birthday:</span></td>
                                            <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Insurance Provider:</span></td>
                                            <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">RBT Required:</span></td>
                                            <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="font-bold">Telehealth Allowed:</span></td>
                                            <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="w-full xl:w-[16rem] m-auto xl:mt-auto xl:mb-1 ml-2">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" onChange={event => setShowImportedHistory(event.target.checked)} checked={showImportedHistory} className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium">Show Imported History</span>
                            </label>
                        </div>

                        <div className={"w-full xl:w-[16rem] ml-2 m-auto " + (showImportedHistory ? '' : 'hidden')}>
                            <label className="block font-bold" htmlFor="centers">
                                Imported History Month:
                            </label>

                            <DatePicker className="w-fit mt-4" disableFuture={true} openTo={'month'} views={['month', 'year']} value={importedHistoryDate} onChange={handleDateChange} renderInput={(params) => <TextField size="small" {...params} />} />
                        </div>


                        <div className={"w-full mx-auto " + (logsLoading ? 'hidden' : '')}>
                            <EDPNLogsList Logs={logs} Therapists={therapists} ApproveLog={OnClickApproveLog} UnapproveLog={OnClickUnapproveLog} DeleteLog={OnClickDeleteLog} HoldLog={OnClickHoldLog} ShowImportedLogs={showImportedHistory} ApproveSet={OnClickApproveSet} ApproveAll={OnClickApproveAllLogs} SetCredentialsModalActive={setCredentialsModalActive} />
                        </div>

                        <div className={'w-full h-auto flex text-center mt-4 ' + (logsLoading ? '' : 'hidden')}>
                            <FontAwesomeIcon className="fill-current text-lacblue text-center w-16 h-16 animate-spin m-auto" icon={solid('spinner')} />
                        </div>

                    </div>
               
                </div>

                <ModalConfirm Message={confirmUnapproveModalMessage} Active={confirmUnapproveModalActive} setActive={setConfirmUnapproveModalActive} OnConfirm={UnapproveLog} />
                <ModalConfirm Message={confirmModalMessage} Active={confirmModalActive} setActive={setConfirmModalActive} OnConfirm={ApproveLog} />
                <ModalConfirm Message={confirmModalMessageLogs} Active={confirmModalActiveLogs} setActive={setConfirmModalActiveLogs} OnConfirm={ApproveLogs} />
                <ModalConfirm Message={confirmDeleteModalMessage} Active={confirmDeleteModalActive} setActive={setConfirmDeleteModalActive} OnConfirm={DeleteLog} />
                <ModalConfirm Message={confirmHoldModalMessage} Active={confirmHoldModalActive} setActive={setConfirmHoldModalActive} OnConfirm={HoldLog} />
                <ModalConfirm Message={confirmModalMessageSetLogs} Active={confirmModalActiveSetLogs} setActive={setConfirmModalActiveSetLogs} OnConfirm={ApproveSet} />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={"<div>This learner has 97151 logs that are not in this set. Please add those logs to the set to continue.</div>"} Active={medicaidCheckModalActive} Icon={"Warning"} setActive={setMedicaidCheckModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning!"} Body={approveMessage} Active={approveModalActive} Icon={"Warning"} setActive={setApproveModalActive} OnModalClose={function noop() { }} />


            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function EDPNViewLogs() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write', 'EDPN.Approve']}>
                    <EDPNViewLogsContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};

function getNextBusinessDate(billingDate: any): number | Date {
    throw new Error("Function not implemented.");
}
