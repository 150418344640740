import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";
/*import toast, { Toaster } from 'react-hot-toast';*/

// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { CenterClinicians } from "../../components/credentials/CenterAdministration/Clinicians";
import { AddClinicianModal } from "../../components/credentials/CenterAdministration/AddClinicianModal";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Center, TherapistSmallObject, VwCenterAllocation } from "../../utils/Definitions";
import Select, { MultiValue } from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ModalConfirm } from "../../components/ModalConfirm";


const PayorAdministrationContent = () => {

    const globalContext = useContext(GlobalContext);

    const [clinicians, setClinicians] = useState<TherapistSmallObject[]>([]);
    const [clinicianToRemove, setClinicianToRemove] = useState<number | null>();

    const [loading, setLoading] = useState<boolean>(true);
    const [isClinicianModalActive, setIsClinicianModalActive] = useState<boolean>(false);
    const [isPermanentModal, setIsPermanentModal] = useState<boolean>(false);
    const [removeClinicianModal, setRemoveClinicianModal] = useState<boolean>(false);

    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);
    const [centerAllocations, setCenterAllocations] = useState<VwCenterAllocation[]>();

    const [permanentClinicians, setPermanentClinicians] = useState<VwCenterAllocation[]>([]);
    const [temporaryClinicians, setTemporaryClinicians] = useState<VwCenterAllocation[]>([]);

    const [currentPagepermanentClinicians, setCurrentPagePermanentClinicians] = useState<VwCenterAllocation[]>([]);
    const [currentPageTemporaryClinicians, setCurrentPageTemporaryClinicians] = useState<VwCenterAllocation[]>([]);

    // Variable that controls how many allocations show per page per table
    const allocationPerPage = 10;

    // Pagination variables
    const [permPage, setPermPage] = useState<number>(1);
    const [tempPage, setTempPage] = useState<number>(1);

    const navigate = useNavigate();

    function NavigateBack() {
        navigate("/credentials");
    }

    useEffect(() => {
        globalContext.UpdateTherapistData();
    }, []);

    useEffect(() => {
        GetCliniciansForDropdown();
    }, [globalContext.centers]);

    useEffect(() => {
        //select first center if there is no selected center
        if (globalContext.centers && globalContext.centers.length > 0 && !selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = globalContext.centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    setSelectedCenter(globalContext.centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                setSelectedCenter(globalContext.centers[0]);
            }
        }

    }, [globalContext.centers]);

    // Populate the Permanent and Temporary table values
    useEffect(() => {
        if (centerAllocations) {

            // This can be limited to the 15 per page based on 15 * page number
            var permClinicians = centerAllocations.filter(x => x.permanentAllocation === true);
            var tempClinicians = centerAllocations.filter(x => x.permanentAllocation === false);

            var currentPagepermClinicians = FilterListByPage(permPage, permClinicians);
            var currentPageTemporaryClinicians = FilterListByPage(tempPage, tempClinicians);

            if (currentPagepermClinicians.length == 0 && permPage != 1) {
                currentPagepermClinicians = FilterListByPage(permPage - 1, permClinicians);
                setPermPage(permPage - 1);
            }
            if (currentPageTemporaryClinicians.length == 0 && tempPage != 1) {
                currentPageTemporaryClinicians = FilterListByPage(tempPage - 1, tempClinicians);
                setTempPage(tempPage - 1);
            }

            setPermanentClinicians(permClinicians);
            setTemporaryClinicians(tempClinicians);

            setCurrentPagePermanentClinicians(currentPagepermClinicians);
            setCurrentPageTemporaryClinicians(currentPageTemporaryClinicians);

        }
    }, [centerAllocations]);

    useEffect(() => {
        if (selectedCenter) {
            setPermPage(1);
            setTempPage(1);

            UpdateCenterAllocations();
        }
    }, [selectedCenter]);

    function NextPage(isPerm: boolean) {
        
        if (isPerm) {
            if (permanentClinicians) {
                setCurrentPagePermanentClinicians(FilterListByPage(permPage + 1, permanentClinicians))
                setPermPage(permPage + 1);
            }
        }
        else {
            if (temporaryClinicians) {
                setCurrentPageTemporaryClinicians(FilterListByPage(tempPage + 1, temporaryClinicians))
                setTempPage(tempPage + 1);
            }
        }
    }

    function PrevPage(isPerm: boolean) {
        if (isPerm) {
            if (permanentClinicians) {
                setCurrentPagePermanentClinicians(FilterListByPage(permPage - 1, permanentClinicians))
                setPermPage(permPage - 1);
            }
        }
        else {
            if (temporaryClinicians) {
                setCurrentPageTemporaryClinicians(FilterListByPage(tempPage - 1, temporaryClinicians))
                setTempPage(tempPage - 1);
            }
        }
    }

    function FilterListByPage(page: number, allocations: VwCenterAllocation[]): VwCenterAllocation[] {
        const minValue = ((page - 1) * allocationPerPage);
        const maxValue = (page * allocationPerPage) > allocations.length ? allocations.length : (page * allocationPerPage);

        return allocations?.slice(minValue, maxValue);
    }

    // Function to get the clinicians for the dropdown in the modal
    // Merge list from global Context with list from call
    function GetCliniciansForDropdown() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllClinicians";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("raw clinicians:", result);
                        setClinicians(result);
                        //CombineEmployees(result);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllClinicians" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function UpdateCenterAllocations() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetCenterAllocations?centerId=" + selectedCenter?.centerId;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("raw center allocations:", result);
                        setCenterAllocations(result);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenterAllocations" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function MapUserForSave(isPermanent: boolean, start: Date, end: Date, clinicianId: number, reason: string): VwCenterAllocation{

        const clincianObject: VwCenterAllocation = {
            centerId: selectedCenter?.centerId ? selectedCenter.centerId : null,
            therapistId: clinicianId,
            dateEffective: start,
            dateEnd: end,
            allocationReason: reason,
            signatureImage: null,
            firstName: null,
            lastName: null,
            jobTitle: null,
            credentials: null,
            permanentAllocation: isPermanent,
            updated: null,
            updatedBy: null,
            bcbacertDate: null
        };

        return clincianObject;
        
    }

    function SaveCenterAllocation(isPermanent: boolean, start: Date, end: Date, clinicianId: number, reason: string) {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(MapUserForSave(isPermanent, start, end, clinicianId, reason))
            };

            var url = apiConfig.apiEndpoint + "/Centers/SaveCenterAllocation";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        UpdateCenterAllocations();
                        setIsClinicianModalActive(false);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/SaveCenterAllocation" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function HandleRemoveClincian(therapistId: number) {
        setClinicianToRemove(therapistId);
        setRemoveClinicianModal(true);
    }

    function RemoveCenterAllocation() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "POST",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/RemoveCenterAllocation?therapistId=" + clinicianToRemove + "&centerId=" + selectedCenter?.centerId;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        UpdateCenterAllocations();
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/RemoveCenterAllocation" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function ShowAddClinicianModal(isPermanent: boolean) {
        setIsPermanentModal(isPermanent);
        setIsClinicianModalActive(true);
    }

    if (centerAllocations && !loading) {
        return (
            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 xl:w-[60rem] w-full h-fit mt-0 mb-8">

                    <div className="mt-1 mb-2 w-full flex">
                        <div onClick={NavigateBack} className="my-2 flex items-center cursor-pointer">
                            <div>
                                <FontAwesomeIcon className="w-8 h-8 mr-3" icon={faArrowLeft} />
                            </div>
                            <span className="text-xl">Back</span>
                        </div>

                        <div className="w-full xl:w-[16rem] m-auto">
                            <label className="block font-bold" htmlFor="centers">
                                Center:
                            </label>
                            <Select options={globalContext.centers} onChange={setSelectedCenter} value={selectedCenter} />
                        </div>
                    </div>

                    <div className="grid grid-flow-row">

                        <div className={"grid grid-cols-1 gap-3 place-items-center w-full m-auto mt-2 h-fit bg-gray-50 border-2 border-lacblue drop-shadow rounded p-2 mb-2"}>
                            <div className="w-full flex items-center justify-between">
                                
                                <h2 className="text-2xl text-lacblue text-center w-fit">Permanent Clinicians</h2>
                                <div className="flex-grow text-right">
                                    <button onClick={() => ShowAddClinicianModal(true)} className="justify-right bg-lacgreen ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600">Add Permanent Clinician</button>
                                </div>
                            </div>

                            <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 w-full">
                                <div className="grid-cols md:flex max-h-[30rem]">
                                    <table className="table-auto w-full">
                                        <thead className="w-full border border-lacblue-500">
                                            <tr className="bg-lacblue text-white">
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Credential</th>
                                                <th>Cert. Date</th>
                                                <th>Signature</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {currentPagepermanentClinicians?.map(allocation => <CenterClinicians centerAllocation={allocation} isPermanent={true} removeClinican={HandleRemoveClincian}></CenterClinicians>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <span onClick={() => PrevPage(true)} className={"flex items-center hover:cursor-pointer " + (permPage == 1 ? "hidden" : "")}><FontAwesomeIcon className="text-lacblue w-5 h-5 px-1 m-auto" icon={solid('arrow-left')}></FontAwesomeIcon>Previous</span>
                                <span className={"font-bold px-5 " + (permanentClinicians.length == 0 ? "hidden" : "")}>Showing {(permPage * allocationPerPage) - (allocationPerPage - 1)}-{(permPage * allocationPerPage) > permanentClinicians.length ? permanentClinicians.length : (permPage * allocationPerPage)} of {permanentClinicians?.length}</span>
                                <span className={"font-bold px-5 " + (permanentClinicians.length == 0 ? "" : "hidden")}>No Permanent Clinicians</span>
                                <span onClick={() => NextPage(true)} className={"flex items-center hover:cursor-pointer " + ((currentPagepermanentClinicians?.length < allocationPerPage) || (permPage * allocationPerPage == permanentClinicians.length) ? "hidden" : "")} >Next<FontAwesomeIcon className="text-lacblue w-5 h-5 px-1 m-auto" icon={solid('arrow-right')}></FontAwesomeIcon></span>
                            </div>

                        </div>

                        <div className={"grid grid-cols-1 gap-3 place-items-center w-full m-auto mt-1 h-fit bg-gray-50 border-2 border-lacblue drop-shadow rounded p-2 mb-4"}>
                            <div className="w-full flex items-center justify-between">

                                <h2 className="text-2xl text-lacblue text-center w-fit">Temporary Clinicians</h2>
                                <div className="flex-grow text-right">
                                    <button onClick={() => ShowAddClinicianModal(false)} className="justify-right bg-lacyellow ml-auto my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600">Add Temporary Clinician</button>
                                </div>
                            </div>

                            <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 w-full">
                                <div className="grid-cols md:flex max-h-[25rem]">
                                    <table className="table-auto w-full">
                                        <thead className="w-full border border-lacblue-500">
                                            <tr className="bg-lacblue text-white">
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Credential</th>
                                                <th>Cert. Date</th>
                                                <th>Signature</th>
                                                <th>End Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {currentPageTemporaryClinicians?.map(allocation => <CenterClinicians centerAllocation={allocation} isPermanent={false} removeClinican={HandleRemoveClincian}></CenterClinicians>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <span onClick={() => PrevPage(false)} className={"flex items-center hover:cursor-pointer " + (tempPage == 1 ? "hidden" : "")}><FontAwesomeIcon className="text-lacblue w-5 h-5 px-1 m-auto" icon={solid('arrow-left')}></FontAwesomeIcon>Previous</span>
                                <span className={"font-bold px-5 " + (temporaryClinicians.length == 0 ? "hidden" : "")}>Showing {(tempPage * allocationPerPage) - (allocationPerPage - 1)}-{(tempPage * allocationPerPage) > temporaryClinicians.length ? temporaryClinicians.length : (tempPage * allocationPerPage)} of {temporaryClinicians?.length}</span>
                                <span className={"font-bold px-5 " + (temporaryClinicians.length == 0 ? "" : "hidden")}>No Temporary Clinicians</span>
                                <span onClick={() => NextPage(false)} className={"flex items-center hover:cursor-pointer " + ((currentPageTemporaryClinicians?.length < allocationPerPage) || (tempPage * allocationPerPage == temporaryClinicians.length) ? "hidden" : "")} >Next<FontAwesomeIcon className="text-lacblue w-5 h-5 px-1 m-auto" icon={solid('arrow-right')}></FontAwesomeIcon></span>
                            </div>

                        </div>

                    </div>

                </div>

                <AddClinicianModal Active={isClinicianModalActive} setActive={setIsClinicianModalActive} isPermanent={isPermanentModal} clinicians={clinicians} saveAllocation={SaveCenterAllocation}></AddClinicianModal>
                <ModalConfirm Message={"Are you sure you want to remove this clinician from this center?"} Active={removeClinicianModal} setActive={setRemoveClinicianModal} OnConfirm={() => RemoveCenterAllocation()} />

            </div>
        )
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



}

export function CenterAdministration() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>



            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Credentials.Read', 'Credentials.Write']}>
                    <PayorAdministrationContent />
                </ProtectedPage>

            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>

        </>
    );
}