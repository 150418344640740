import { text } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion } from "../../utils/Definitions";

interface Props {
    EDPNQuestion: EDPNQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;

}

export const EDPNQuestionTypeTextSplit: React.FC<Props> = (props) => {

    const [textValue, setTextValue] = useState<string>("");

    const edpnContext = useContext(EDPNContext);


    useEffect(() => {
        if (edpnContext.selectedEditAnswer && props.answerObject && props.answerObject.answers && props.EDPNQuestion) {
            var tempanswer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);

            if (tempanswer && tempanswer.textValue) {
                //autopopulate text value
                setTextValue(tempanswer.textValue);
            }
        }

    }, [props.answerObject, props.answerObject?.answers, props.EDPNQuestion]);

    useEffect(() => {
        //autopopulate answer object
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (!answer) {

                var AutoPopulatedText = edpnContext.selectedEditAnswer?.answers?.find(x => x.questionId == props.EDPNQuestion.questionId)?.textValue;

                console.log("defaultText:", props.EDPNQuestion.defaultText);

                if (edpnContext.selectedEditAnswer == null) {
                    AutoPopulatedText = props.EDPNQuestion.defaultText;
                }

                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: AutoPopulatedText ? AutoPopulatedText : null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }

    }, [edpnContext.selectedEditAnswer, props.answerObject]);

    function SaveToObject(input: string) {
        if (props.answerObject && props.answerObject.answers) {

            //if answer already exists, update
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.textValue = input);
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: input,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
    }

    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto mb-1 col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>
                <input onCut={(event) => event.preventDefault()} onCopy={(event) => event.preventDefault()} onPaste={(event) => event.preventDefault()} disabled={edpnContext.isViewOnly} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={(event) => SaveToObject(event.target.value)} defaultValue={textValue} autoComplete="off" type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 col-span-12" />
            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-6">
                <label className="block font-bold">
                    {props.EDPNQuestion.questionText}:
                </label>
                <input onCut={(event) => event.preventDefault()} onCopy={(event) => event.preventDefault()} onPaste={(event) => event.preventDefault()} disabled={edpnContext.isViewOnly} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={(event) => SaveToObject(event.target.value)} defaultValue={textValue} autoComplete="off" type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
            </div>
        )
    }

};

