import { createContext, useContext, useEffect, useState } from 'react'
import { apiConfig } from '../../authConfig';
import { Center, ChoiceOption, Client, OutcomeEvaluation, Pod, VwOutcomesEvaluation, Therapist, TherapistCredentialNote, Schedule } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';


type CredentialsContextProviderProps = {
    children: React.ReactNode
}

type CredentialsContextType = {
    selectedTherapist: Therapist | undefined;
    setSelectedTherapist: any;
    selectedCenter: Center | null;
    setSelectedCenter: React.Dispatch<React.SetStateAction<Center | null>>;
    getAllTherapists: boolean;
    setGetAllTherapists: React.Dispatch<React.SetStateAction<boolean>>;
    therapistNoteModalActive: boolean;
    setTherapistNoteModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    newCenterCredentialsCardActive: boolean;
    setNewCenterCredentialsCardActive: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTherapistNote: TherapistCredentialNote | null;
    setSelectedTherapistNote: React.Dispatch<React.SetStateAction<TherapistCredentialNote | null>>;
    clientRelationships: ChoiceOption[] | null;
    setClientRelationships: React.Dispatch<React.SetStateAction<ChoiceOption[] | null>>;
    fundingTypes: ChoiceOption[] | null;
    setFundingTypes: React.Dispatch<React.SetStateAction<ChoiceOption[] | null>>;
    companyInsurances: ChoiceOption[] | null;
    transitionReasons: ChoiceOption[] | null;
    dischargeReasons: ChoiceOption[] | null;
    schedules: Schedule[];
}

export const CredentialsContext = createContext<CredentialsContextType>({
    selectedTherapist: undefined,
    setSelectedTherapist: function noop() { },
    selectedCenter: null,
    setSelectedCenter: function noop() { },
    getAllTherapists: false,
    setGetAllTherapists: function noop() { },

    therapistNoteModalActive: false,
    setTherapistNoteModalActive: function noop() { },

    newCenterCredentialsCardActive: false,
    setNewCenterCredentialsCardActive: function noop() { },

    selectedTherapistNote: null,
    setSelectedTherapistNote: function noop() { },
    clientRelationships: null,
    setClientRelationships: function noop() { },
    fundingTypes: null,
    setFundingTypes: function noop() { },
    companyInsurances: null,
    transitionReasons: null,
    dischargeReasons: null,
    schedules: [],
});


export const CredentialsContextProvider = ({ children, }: CredentialsContextProviderProps) => {

    const globalContext = useContext(GlobalContext);

    const [selectedTherapist, setSelectedTherapist] = useState<Therapist>();
    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);

    const [getAllTherapists, setGetAllTherapists] = useState<boolean>(false);

    const [therapistNoteModalActive, setTherapistNoteModalActive] = useState<boolean>(false);
    const [newCenterCredentialsCardActive, setNewCenterCredentialsCardActive] = useState<boolean>(false);
    const [selectedTherapistNote, setSelectedTherapistNote] = useState<TherapistCredentialNote | null>(null);

    const [clientRelationships, setClientRelationships] = useState<ChoiceOption[] | null>(null);
    const [fundingTypes, setFundingTypes] = useState<ChoiceOption[] | null>(null);
    const [companyInsurances, setCompanyInsurances] = useState<ChoiceOption[] | null>(null);
    const [transitionReasons, setTransitionReasons] = useState<ChoiceOption[] | null>(null);
    const [dischargeReasons, setDischargeReasons] = useState<ChoiceOption[] | null>(null);
    const [schedules, setSchedules] = useState<Schedule[]>([]);


    const CredentialsContextExport: CredentialsContextType = {
        selectedTherapist: selectedTherapist,
        setSelectedTherapist: setSelectedTherapist,
        selectedCenter: selectedCenter,
        setSelectedCenter: setSelectedCenter,
        getAllTherapists: getAllTherapists,
        setGetAllTherapists: setGetAllTherapists,
        therapistNoteModalActive: therapistNoteModalActive,
        setTherapistNoteModalActive: setTherapistNoteModalActive,
        newCenterCredentialsCardActive: newCenterCredentialsCardActive,
        setNewCenterCredentialsCardActive: setNewCenterCredentialsCardActive,
        selectedTherapistNote: selectedTherapistNote,
        setSelectedTherapistNote: setSelectedTherapistNote,
        clientRelationships: clientRelationships,
        setClientRelationships: setClientRelationships,
        fundingTypes: fundingTypes,
        setFundingTypes: setFundingTypes,
        companyInsurances: companyInsurances,
        transitionReasons: transitionReasons,
        dischargeReasons: dischargeReasons,
        schedules: schedules,
    };

    useEffect(() => {
        GetAllBillingSchedules();
    }, []);

    useEffect(() => {
        UpdateClientRelationships();
        UpdateFundingTypes();
        UpdateCompanyInsurances();
        UpdateTransitionReasons();
        UpdateDischargeReasons();
    }, [globalContext.CoreAccount.token]);

    function UpdateClientRelationships() {
        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.roles.includes('ADMIN') || globalContext.CoreAccount.roles.includes('Administration.Learner.Write')) {

                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Clients/GetClientRelationships", options)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            const mappedRelationships: ChoiceOption[] = result.map((relationship: any) => ({
                                value: relationship.relationshipId.toString(),  // Assuming RelationshipId is a number, convert to string if needed
                                label: relationship.description
                            }));

                            setClientRelationships(mappedRelationships);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetClientRelationships" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    function GetAllBillingSchedules() {

        globalContext.GetToken().then((token: any) => {

            if (token) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Billing/GetAllBillingDaySchedules";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setSchedules(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Billing/GetAllBillingDaySchedules" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }


    function UpdateFundingTypes() {
        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.roles.includes('ADMIN') || globalContext.CoreAccount.roles.includes('Administration.Learner.Write')) {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Clients/GetFundingTypes", options)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            console.log("raw funding types:", result);

                            const mappedObjects: ChoiceOption[] = result.map((object: any) => ({
                                value: object.fundingTypeId.toString(),
                                label: object.fundingType1
                            }));

                            setFundingTypes(mappedObjects);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetFundingTypes" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }


            
        })
    }

    

    function UpdateCompanyInsurances() {
        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.roles.includes('ADMIN') || globalContext.CoreAccount.roles.includes('Administration.Learner.Write')) {

                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Clients/GetCompanyInsurances", options)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            const activeObjects = result.filter((object: any) => object.active);

                            const mappedObjects: ChoiceOption[] = activeObjects.map((object: any) => ({
                                value: object.id.toString(),
                                label: object.companyInsuranceName
                            }));

                            setCompanyInsurances(mappedObjects);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetCompanyInsurances" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    function UpdateTransitionReasons() {
        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.roles.includes('ADMIN') || globalContext.CoreAccount.roles.includes('Administration.Learner.Write')) {

                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Clients/GetTransitionReasons", options)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            const mappedObjects: ChoiceOption[] = result.map((object: any) => ({
                                value: object.transitionReasonId.toString(),
                                label: object.reason
                            }));

                            setTransitionReasons(mappedObjects);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetTransitionReasons" });
                            globalContext.setErrorModalActive(true);
                        }
                )
            }
        })
    }
    function UpdateDischargeReasons() {
        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.roles.includes('ADMIN') || globalContext.CoreAccount.roles.includes('Administration.Learner.Write')) {

                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                fetch(apiConfig.apiEndpoint + "/Clients/GetDischargeReasons", options)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            const mappedObjects: ChoiceOption[] = result.map((object: any) => ({
                                value: object.dischargeReasonId.toString(),
                                label: object.reason
                            }));

                            setDischargeReasons(mappedObjects);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetDischargeReasons" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    return <CredentialsContext.Provider value={CredentialsContextExport}>{children}</CredentialsContext.Provider>;
}