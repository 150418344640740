import { useContext, useEffect, useState } from "react";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { GlobalContext } from "../../utils/GlobalContext";

import { EDPNAnswer, EDPNAnswerObject, EDPNQuestion } from "../../utils/Definitions";
import { apiConfig } from "../../authConfig";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
    EDPNQuestion: EDPNQuestion,
    setAnswerObject: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>;
    answerObject: EDPNAnswerObject | null;

}

interface ChoiceOption {
    value: string,
    label: string
}

export const EDPNQuestionTypeChoice: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);

    const [selectedOption, setSelectedOption] = useState<ChoiceOption | null>();

    const [options, setOptions] = useState<ChoiceOption[]>();

    useEffect(() => {
        if (globalContext.CoreAccount.token && props.EDPNQuestion) {
            UpdateOptions();
        }
    }, [globalContext.CoreAccount.token, props.EDPNQuestion]);

    useEffect(() => {
        //if answer already exists, update
        if (props.answerObject && props.answerObject.answers) {
            var answer = props.answerObject.answers.find(x => x.questionId == props.EDPNQuestion.questionId);
            if (answer) {

                var tempAnswers = props.answerObject.answers;
                if (tempAnswers) {
                    if (selectedOption) {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.choiceValue = Number(selectedOption.value));
                    }
                    else {
                        tempAnswers.filter(x => x.questionId == props.EDPNQuestion.questionId).forEach(x => x.choiceValue = -1);
                    }
                }

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
            else {
                //if answer doesn't already exist, create new
                var tempAnswer: EDPNAnswer = {
                    answerId: null,
                    questionId: props.EDPNQuestion.questionId,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: selectedOption ? Number(selectedOption.value) : -1,
                    log: null,
                    question: null
                };

                var tempAnswers = props.answerObject.answers;
                tempAnswers.push(tempAnswer);

                props.setAnswerObject({ ...props.answerObject, answers: tempAnswers });
            }
        }
        
    }, [selectedOption]);


    function UpdateOptions() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = apiConfig.apiEndpoint + "/EDPN/GetChoices?questionid=" + props.EDPNQuestion?.questionId;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setOptions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetChoices" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function OpenExample() {
        if (props.EDPNQuestion.exampleText) {
            edpnContext.setExampleMessage(props.EDPNQuestion.exampleText);
            edpnContext.setExampleModalActive(true);
        }
    }

    //auto populate rbt
    useEffect(() => {
        if (edpnContext.selectedEditAnswer && selectedOption == null && options) {
            if (edpnContext.selectedEditAnswer && props.EDPNQuestion) {
                var tempanswer = edpnContext.selectedEditAnswer.answers?.find(x => x.questionId == props.EDPNQuestion.questionId);
                if (tempanswer && tempanswer.choiceValue) {
                    var temp = options.find(x => x.value == String(tempanswer?.choiceValue));
                    if (temp) {
                        setSelectedOption(temp);
                    }
                }
            }
        }

    }, [edpnContext.selectedEditAnswer, props.EDPNQuestion, options]);

    if (props.EDPNQuestion.exampleText) {
        return (
            <div className="w-full m-auto mt-4 col-span-12 grid grid-cols-12">
                <label className="font-bold col-span-11">
                    {props.EDPNQuestion.questionText}:
                </label>
                <div onClick={OpenExample} className="cursor-pointer ml-auto col-span-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle text-lacblue hover:text-lacblue-500" icon={solid('circle-info')} />
                </div>
                <Select className="col-span-12 mt-1" isSearchable={false} isDisabled={edpnContext.isViewOnly} options={options} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedOption} value={selectedOption} />
            </div>
        )
    }
    else {
        return (
            <div className="w-full m-auto mt-4 col-span-12">
                <label className="font-bold">
                    {props.EDPNQuestion.questionText}:
                </label>
                <Select className="mt-1" isSearchable={false} isDisabled={edpnContext.isViewOnly} options={options} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedOption} value={selectedOption} />
            </div>
        )
    }
    

};

