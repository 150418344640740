import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";

// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { CredentialsContext } from "./CredentialsContext";
import { CredentialsTherapistCard } from "../../components/credentials/CredentialsTherapistCard";
import { useNavigate } from "react-router-dom";
import { Center, Therapist, BCBATherapist } from "../../utils/Definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalNotification } from "../../components/ModalNotification";
import { zonedTimeToUtc } from 'date-fns-tz'



const CredentialsBCBAAdminContent = () => {

    const navigate = useNavigate();

    const credentialsContext = useContext(CredentialsContext);
    const globalContext = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [centers, setCenters] = useState<Center[]>();
    const [therapists, setTherapists] = useState<BCBATherapist[]>();
    const [isRBT, setIsRBT] = useState<boolean>(false);

    const [clientFilter, setClientFilter] = useState<string>('');
    function HandleClientFilterChange(event: { target: { value: string; }; }) {
        setClientFilter(event.target.value);
    }

    const [selectedTherapistForModal, setSelectedTherapistForModal] = useState<Therapist | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedTherapistForModal(null);
    }

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    useEffect(() => {
        const utcDate = zonedTimeToUtc(new Date(), "America/Chicago");
        console.log("utcdate:", utcDate.toString());
    }, []);


    function NavigateBack() {
        navigate("/credentials");
    }


    function NavigateBCBACredentials(therapist: Therapist) {

        navigate("/credentials/bcbacredentials")
    }

    function UpdateCenters() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("rawcenters:", result);
                        setCenters(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }


    function UpdateTherapists() {
        if (credentialsContext.selectedCenter) {
            setLoading(true);
            globalContext.GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };
                var url = "";
                if (isRBT) {
                    url = apiConfig.apiEndpoint + "/Credentials/GetRBTTherapists?centerId=" + credentialsContext.selectedCenter?.centerId;
                }
                else {
                    url = apiConfig.apiEndpoint + "/Credentials/GetBCBATherapists?centerId=" + credentialsContext.selectedCenter?.centerId;
                }
                
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("raw therapists:", result);
                            setTherapists(result);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateTherapists();
        }
    }, [globalContext.CoreAccount.token, isRBT]);


    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateCenters();
        }
    }, [globalContext.CoreAccount.token]);

    //update centers
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateTherapists();
        }
    }, [globalContext.CoreAccount.token, credentialsContext.selectedCenter]);



    useEffect(() => {
        //select first center if there is no selected center
        if (centers && centers.length > 0 && !credentialsContext.selectedCenter) {

            //attempt to default to user's center if they have one
            if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.centerID && globalContext.CoreAccount.currentUser.centerID != null) {
                var center = centers.find(x => globalContext.CoreAccount && globalContext.CoreAccount.currentUser && x.centerId == globalContext.CoreAccount.currentUser.centerID)
                if (center) {
                    credentialsContext.setSelectedCenter(center);
                }
                else {
                    //if user doesnt have a center, select first index
                    credentialsContext.setSelectedCenter(centers[0]);
                }
            }
            else {
                //if user doesnt have a center, select first index
                credentialsContext.setSelectedCenter(centers[0]);
            }

        }

    }, [centers]);


    //clients
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateTherapists();
        }
    }, [credentialsContext.selectedTherapist]);

    //update clients on toggle change
    useEffect(() => {
        if (therapists) {
            UpdateTherapists();
        }

    }, [credentialsContext.getAllTherapists]);


    if (!loading) {

        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">

                    <div onClick={NavigateBack} className="my-2 flex items-center justify-center w-fit cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Admin Home</span>
                        <div className="flex-1"></div>
                    </div>

                    <div className="grid grid-flow-row">

                        {/*<div className="grid grid-cols-1 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:grid-cols-4 xl:w-fit xl:m-auto xl:mt-4">*/}
                        <div className="grid grid-cols-1 gap-4 gap-[4.5rem] content-between grid-flow-col grid-cols-1 w-fit m-auto mt-4">

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="search">
                                    Search:
                                </label>
                                <input autoComplete="off" onChange={HandleClientFilterChange} type="text" id="search" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" placeholder="Search..." />
                            </div>

                            <div className="grid content-center">
                                
                                <label className="relative inline-flex float-right cursor-pointer">
                                    <input type="checkbox" className="sr-only peer" onChange={event => setIsRBT(!isRBT)} checked={isRBT} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">RBT Credentials</span>
                                </label>
                                
                            </div>

                        </div>

                        <div className="grid grid-cols-1 gap-6 place-items-center xl:grid-cols-2 w-fit m-auto mt-8">
                            {therapists ? therapists.filter(therapist => therapist.employeeName.toLowerCase().includes(String(clientFilter.toLowerCase()))).map(therapist => <CredentialsTherapistCard SetProfilePictureModalActive={setProfilePictureModalActive} key={therapist.therapistId} Therapist={therapist} SetSelectedTherapistForModal={setSelectedTherapistForModal} SetCredentialsModalActive={setCredentialsModalActive} centers={centers} />) : <></>}
                        </div>

                    </div>

                </div>

                {/*<ProfilePictureModal Therapist={selectedTherapistForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                */}
                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />

            </div>

        );


    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>        
        );
    }
};


export function CredentialsBCBAAdmin() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Credentials.Read', 'Credentials.Write']}>
                    <CredentialsBCBAAdminContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>

            
        </>
    );
}