import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { TherapistCredentialNote, CredentialNoteStatus } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';
import { apiConfig } from "../../authConfig";
import { CredentialsContext } from '../../pages/credentials/CredentialsContext';
import Select, { MultiValue } from "react-select";

interface Props {
    TherapistId: number,
    Active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>,
    UpdateNotes: any;
}


export const CredentialNotesModal: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);
    const credentialsContext = useContext(CredentialsContext);
    const [note, setNote] = useState<string | undefined>();
    const [selectedTherapistStatus, setSelectedTherapistStatus] = useState<CredentialNoteStatus | null>();
    const [noteDate, setNoteDate] = useState<Date>(new Date());

    const [therapistCredentialStatus, setTherapistCredentialStatus] = useState<CredentialNoteStatus[]>();


    //update modal on active change
    useEffect(() => {

    }, [props.Active]);

    function CloseModal() {
        credentialsContext.setSelectedTherapistNote(null);
        props.setActive(false);
    }

    function UpdateCredentialStatus() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Credentials/GetCredentialStatus", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("Credential Status:", result);
                        setTherapistCredentialStatus(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetCredentialStatus" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function SaveNote() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "POST",
                headers: headers
            };
            fetch(apiConfig.apiEndpoint + "/Credentials/SaveTherapistNote?therapistId=" + props.TherapistId + "&statusId=" + selectedTherapistStatus?.statusId + "&note=" + note, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("Update Result:", result);
                        props.UpdateNotes();
                        CloseModal();
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/SaveTherapistNote" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })

    }

    function DeleteTherapistNote() {
        if (props.TherapistId && credentialsContext.selectedTherapistNote) {

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();

                var bearer = "Bearer " + token;

                headers.append("Authorization", bearer);
                var options = {
                    method: "POST",
                    headers: headers
                };
                console.log("deleting therapist note : ", credentialsContext.selectedTherapistNote);
                fetch(apiConfig.apiEndpoint + "/Credentials/DeleteTherapistCredentialNote?noteId=" + credentialsContext.selectedTherapistNote?.noteId, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("Deleted successfully:", result);
                            props.UpdateNotes();
                            CloseModal();
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/DeleteTherapistCredentialNote" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }
    }


    //update statuses
    useEffect(() => {
        if (globalContext.CoreAccount.token) {
            UpdateCredentialStatus();
        }
    }, [globalContext.CoreAccount.token]);

    //update statuses


    useEffect(() => {
        console.log("selected New TherapistNote");
        //select first Credential Status if there is no selected center
        if (therapistCredentialStatus && therapistCredentialStatus.length > 0) {
            if (credentialsContext.selectedTherapistNote) {
                var credential = therapistCredentialStatus.find(x => x.statusId === credentialsContext.selectedTherapistNote?.statusId)
                if (credential) {
                    setSelectedTherapistStatus(credential);
                }
            }
            else {
                setSelectedTherapistStatus(therapistCredentialStatus[0]);
            }
        }

    }, [credentialsContext.selectedTherapistNote]);

    return (
        <div className={"z-10 relative " + (props.Active ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg font-medium leading-6 text-center" id="modal-title">New Credentialing Note</h3>
                                    <div className="mt-2 grid grid-cols-3">
                                        <div className=" grid grid-cols-1 w-full m-auto">
                                            <label className="block font-bold" htmlFor="pods">
                                                Credential Status:
                                            </label>
                                        </div>
                                        <div className=" grid grid-cols-2 w-full m-auto">
                                            <Select className="w-[9rem]" options={therapistCredentialStatus} onChange={setSelectedTherapistStatus} value={selectedTherapistStatus} />
                                        </div>
                                        <div><span className="grid grid-cols-1 float-right font-bold m-auto">Date: {credentialsContext.selectedTherapistNote ? new Date(credentialsContext.selectedTherapistNote.noteDate).toLocaleDateString() : new Date().toLocaleDateString()}</span></div>
                                    </div>
                                    <div className="mt-2 w-full">
                                        <textarea id="therapistNote" className="block p-2.5 w-full h-48 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            defaultValue={credentialsContext.selectedTherapistNote ? credentialsContext.selectedTherapistNote.notes : ""}
                                            onChange={(event) => setNote(event.target.value)} 
                                            maxLength={256}
                                            placeholder="Write your notes here..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex bg-gray-50 px-4 py-3  sm:px-6 mx-auto">
                            <div>
                                <FontAwesomeIcon className={"float-left text-lacblue w-7 h-7 m-auto " + (credentialsContext.selectedTherapistNote ? "normal" : "hidden")} icon={solid('trash-can')} onClick={() => DeleteTherapistNote()} ></FontAwesomeIcon>
                            </div>

                            <div className="flex justify-center mx-auto">
                                <div className="pr-5">
                                    <button onClick={CloseModal} className="bg-lacblue w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Cancel</span></button>
                                </div>

                                <div>
                                    <button onClick={SaveNote} className="bg-lacblue w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600"><span className="drop-shadow">Add Note</span></button>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}