import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { Client } from '../utils/Definitions';

import { GlobalContext } from "../utils/GlobalContext";
import { apiConfig } from "../authConfig";
import { profile } from 'console';

interface Props {
    ClientID: number | null | undefined,
    FirstName: string | null | undefined,
    LastName: string | null | undefined,
}

export const ProfilePictureSmall: React.FC<Props> = (props) => {

    const globalContext = useContext(GlobalContext);

    const [profilePicture, setProfilePicture] = useState<string>("");

    useEffect(() => {

        if (globalContext.CoreAccount.token && props.ClientID) {
            GetProfilePicture(props.ClientID);
        }
        else {
            setProfilePicture("");
        }
    }, [globalContext.CoreAccount.token, props.ClientID]);

    async function GetProfilePicture(clientID: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Clients/GetProfilePicture?thumb=" + true + "&clientid=" + clientID;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.blob();

            if (result.size > 0) {
                setProfilePicture(URL.createObjectURL(result));
            }
            else {
                setProfilePicture("");
            }

            return result;
        } catch (error) {

            /*
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Clients/GetProfilePicture" });
            globalContext.setErrorModalActive(true);
            */
            return false;
        }
    }

    return (
        <div className="w-[5rem] h-[5rem] group ring ring-lacblue ring-offset-4 m-auto m-2 rounded-full bg-gray flex items-center justify-center relative inline-block noprint">
            <img src={profilePicture} alt="Profile Picture" className={"w-[5rem] h-[5rem] object-cover ring ring-lacblue ring-offset-4 m-auto rounded-full " + (profilePicture !== "" ? "normal" : "hidden")}></img>
            <span className={"text-gray-200 font-bold text-2xl text-center " + (profilePicture === "" ? "normal" : "hidden")}>{props.FirstName && props.LastName ? props.FirstName[0].toUpperCase() + props.LastName[0].toUpperCase() : ""}</span>
        </div>
    )

}
