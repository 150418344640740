import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EDPNContext } from "../../pages/edpn/EDPNContext";
import { EDPNAnswerObject, EDPNComment, Therapist, Schedule } from "../../utils/Definitions";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { apiConfig } from "../../authConfig";
import { GlobalContext } from "../../utils/GlobalContext";
import { CredentialsContext } from "../../pages/credentials/CredentialsContext";
import { format, isAfter, set, sub } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";


interface Props {
    Log: EDPNAnswerObject,
    Therapists: Therapist[] | null,
    SelectRow: any,
    DeleteLog: any,
    PreviewLog: React.Dispatch<React.SetStateAction<boolean>>,
    SetCredentialsModalActive: React.Dispatch<React.SetStateAction<boolean>>,
    HoveredLog: React.Dispatch<React.SetStateAction<EDPNAnswerObject | null>>,
    setApprovalsPageLoading: React.Dispatch<React.SetStateAction<boolean>>,
    selectedApprovalsList: EDPNAnswerObject[],
    UnapproveLog: any,
}

export const EDPNApprovalListCard: React.FC<Props> = (props) => {

    // Accessing the global context using the useContext hook
    const globalContext = useContext(GlobalContext);

    const credentialsContext = useContext(CredentialsContext);

    // Accessing the EDPN context using the useContext hook
    const edpnContext = useContext(EDPNContext);

    // Accessing the navigate function using the useNavigate hook
    const navigate = useNavigate();

    const [filteredComments, setFilteredComments] = useState<EDPNComment[] | null>(null);

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>(null);


    useEffect(() => {
        var comments = props.Log.comments?.filter(x => x.refId == props.Log.logId || x.refId == null);
        if (comments && comments.length > 0) {
            setFilteredComments(comments);
        }
        else {
            setFilteredComments(null);
        }

    }, [props.Log.comments]);


    useEffect(() => {
        if (props.Log) {
            GetSchdedule();
        }
    }, []);

    // This is an asynchronous function that navigates to the edit log page
    async function NavigateToEditLog() {

        if (props.Log && props.Log.clientId && props.Log.logId) {

            props.setApprovalsPageLoading(true);

            const client = await GetClientByID(props.Log.clientId);
            const log = await GetLogByID(props.Log.logId);

            props.setApprovalsPageLoading(false);

            edpnContext.setSelectedClient(client);
            edpnContext.setSelectedEditAnswer(log);
            edpnContext.setIsViewOnly(false);

            if (props.Log.absent == false) {
                navigate("/edpn/approvals/editlog");
            }
            else {
                navigate("/edpn/approvals/editabsent");
            }
        }

    }

    function GetSchdedule() {
        if (props.Log.billingDate) {
            var dayID = new Date(props.Log.billingDate).getDay() + 1;

            var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == props.Log.therapyScheduleId && x.dayOfWeekId == dayID);

            if (schedule) {
                setBillingDaySchedule(schedule);
            }
        }
    }

    /*async function GetBillingDaySchedule() {
        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Billing/GetBillingDayScheduleForClient?therapyScheduleId=" + props.Log.therapyScheduleId + "&billingDate=" + props.Log.billingDate;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            setBillingDaySchedule(result);
            //return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Billing/GetBillingDayScheduleForClient" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }*/

    // This is an asynchronous function that retrieves a client object by ID
    async function GetClientByID(id: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Clients/GetClientByID?clientid=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Clients/GetClientByID" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    async function GetLogByID(id: string) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/EDPN/GetLog?id=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/EDPN/GetLog" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    const PreviewLog = () => {
        props.HoveredLog(props.Log);
        props.PreviewLog(true);
    }

    const StopPreviewLog = () => {
        props.HoveredLog(null);
        props.PreviewLog(false);
    }


    const DeleteUnapproveButton = () => {

        if (props.Log.dpnstatusId != null && [2, 4, 6, 7].includes(props.Log.dpnstatusId)) {
            return (
                <td className={"border border-gray-500"} onClick={() => props.DeleteLog(props.Log)}><span><FontAwesomeIcon className="float-right text-lacblue w-5 h-5 px-1 m-auto hover:cursor-pointer" icon={solid('trash-can')}></FontAwesomeIcon></span></td>
            )
        }
        else if (props.Log.dpnstatusId != null && props.Log.dpnstatusId === 1 && props.Log.harvested === false) {
            return (
                <td className={"border border-gray-500"} onClick={() => props.UnapproveLog(props.Log)}><span><FontAwesomeIcon className="float-right text-pink w-5 h-5 px-1 m-auto hover:cursor-pointer" icon={solid('rotate-left')}></FontAwesomeIcon></span></td>
            )
        }
        else {
            return (
                <td className="hidden"></td>
            )
        }
    }


    if (true && props.Log.logId) {
        return (

            <tr key={props.Log.logId} className="text-center hover:bg-gray-200 border border-gray-500">
                <td className="border border-gray-500" onMouseOver={PreviewLog} onMouseOut={StopPreviewLog}><span><FontAwesomeIcon className="float-right text-lacblue w-5 h-5 px-1 m-auto" icon={solid('eye')}></FontAwesomeIcon></span></td>
                <td className="border border-gray-500">
                    {props.Log.dpnstatusId == 1 && <span className="text-lacgreen">Approved</span>}

                    {props.Log.dpnstatusId == 2 && <span className="text-yellow">Needs Edit</span>}

                    {(props.Log.dpnstatusId == 7 || props.Log.dpnstatusId == 4) &&
                        <ProtectedComponent RequiredRoles={['EDPN.Approve']}>
                            <input id={props.Log.logId} type="checkbox" checked={props.selectedApprovalsList.filter(x => x.logId == props.Log.logId).length == 1} onChange={event => props.SelectRow(props.Log, event.target.checked)} />
                        </ProtectedComponent>
                    }
                </td>
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500">{props.Log.clientName && <span>{props.Log.clientName}</span>}</td>
                <td onClick={NavigateToEditLog} className={"border hover:cursor-pointer border-gray-500 " + (props.Log.dpnactualDuration && props.Log.scheduleDuration && props.Log.dpnactualDuration < (props.Log.scheduleDuration - 0.12) ? 'bg-rose-200' : '')}>{props.Log.dpnactualDuration && <span>{props.Log.dpnactualDuration}</span>}</td>
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500">{props.Log.absent === true ? "Yes" : "No"}</td>
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500">{props.Log.startTime && props.Log.endTime ? <span>{new Date(props.Log.startTime).toLocaleTimeString()} - {new Date(props.Log.endTime).toLocaleTimeString()}</span> : <span>N/A</span>}</td>
                {/*<td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500"><span>{props.Log.therapySchedule}</span></td>*/}
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500"><span>{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span></td>
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500"><span>{props.Log.tardyReason}</span></td>
                <td onClick={NavigateToEditLog} className="border hover:cursor-pointer border-gray-500">{props.Log.billingDate && <span>{new Date(props.Log.billingDate).toLocaleDateString()}</span>}</td>
                <DeleteUnapproveButton/>
                <td className={"border border-gray-500 " + (filteredComments && filteredComments.length > 0 ? "" : "hidden")}><span><FontAwesomeIcon className="float-right text-lacblue w-5 h-5 px-1 m-auto hover:cursor-pointer" icon={solid('message')}></FontAwesomeIcon></span></td>
            </tr>
        )
    }
    else {
        return (

            <div className="h-fit bg-gray-50 border drop-shadow rounded p-2 max-w-[47rem] w-full xl:w-fit">

                <div className="grid-cols md:flex h-fit">

                    <table>

                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Learner</th>
                                <th>Client #</th>
                                <th>Absent</th>
                                <th>Start & End Time</th>
                                <th>Tx Schedule</th>
                                <th>Hours Missed Reason</th>
                                <th>Service Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td><span>Approved</span></td>
                                <td>{props.Log.clientName && <span>{props.Log.clientName}</span>}</td>
                                <td><span>{props.Log.therapistId?.toString()}</span></td>
                                <td>{props.Log.absent === true ? "Yes" : "No"}</td>
                                <td>{props.Log.startTime && <span>{props.Log.clientName}</span>}</td>
                                <td><span>6</span></td>
                                <td><span>7</span></td>
                                <td><span>8</span></td>
                            </tr>
                        </tbody>

                    </table>

                </div>
            </div>
        )
    }



};

